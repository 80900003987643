import React from 'react';
import _ from 'lodash';
import { Route, IndexRoute, browserHistory } from 'react-router';
import * as Links from 'datasetManagementUI/links/links';
import Home from 'datasetManagementUI/pages/Home/Home';
import ShowRevision from 'datasetManagementUI/pages/ShowRevision/ShowRevision';
import { ManageColumnMetadata } from 'datasetManagementUI/containers/ManageColumnMetadataContainer';
import ManageAssetMetadata from 'datasetManagementUI/containers/ManageMetadataComponentContainer.ts';
import ShowOutputSchema from 'datasetManagementUI/pages/ShowOutputSchema/ShowOutputSchema';
import TablePane from 'datasetManagementUI/pages/ShowOutputSchema/TablePane';
import ParseOptionsPane from 'datasetManagementUI/pages/ShowOutputSchema/ParseOptionsPane';
import SchemaMismatchPane from 'datasetManagementUI/pages/ShowOutputSchema/SchemaMismatchPane';
import GeocodeShortcutPane from 'datasetManagementUI/pages/ShowOutputSchema/GeocodeShortcutPane';
import TransformColumnPane from 'datasetManagementUI/pages/ShowOutputSchema/TransformColumnPane';
import AddColPane from 'datasetManagementUI/pages/ShowOutputSchema/AddColPane';
import ShowSource from 'datasetManagementUI/pages/ShowSource/ShowSource';
import { focusColumnEditor } from 'datasetManagementUI/reduxStuff/actions/manageMetadata';
import NoMatch from 'datasetManagementUI/pages/NoMatch/NoMatch';
import DsmuiDragDropUpload from 'datasetManagementUI/components/DragDropUpload/DsmuiDragDropUpload';
import URLSource from 'datasetManagementUI/containers/URLSourceContainer';
import AgentList from 'datasetManagementUI/containers/AgentListContainer';
import AgentNamespace from 'datasetManagementUI/containers/AgentNamespaceContainer';
import HrefForm from 'datasetManagementUI/containers/HrefFormContainer';
import ShowBlobPreviewFromView from 'datasetManagementUI/containers/ShowBlobPreviewFromViewContainer';
import ShowBlobPreviewFromSource from 'datasetManagementUI/containers/ShowBlobPreviewFromSourceContainer';
import EditSoQLQuery from 'datasetManagementUI/components/EditSoQLQuery/EditSoQLQuery';
import * as ModeGrantActions from 'datasetManagementUI/reduxStuff/actions/modeGrant';
import { loadRevision } from 'datasetManagementUI/reduxStuff/actions/loadRevision';
import { currentRevision } from 'datasetManagementUI/selectors';

// How did this ever work?
// The react-router entry point is like the first thing to run, but we fetch
// output schema stuff asynchronously.
const checkSchemaStatus = (store) => (nextState, replace, cb) => {
  const osid = _.toNumber(nextState.params.outputSchemaId);
  // Edit Col metadata only works if we have an output schema, so check that
  // one exists before allowing access to the page
  const osExists = !_.isEmpty(store.getState().entities.output_schemas[osid]);

  if (osExists) {
    store.dispatch(focusColumnEditor(nextState));
    cb();
  } else {
    const newPath = Links.home(nextState.params);
    replace(newPath);
    cb();
  }
};

// Not using callback here because we don't want this to block.
// Let it go ahead and render Home while the api call is happening.
// Home is smart enought to show a spinner til it has the info it
// needs to render.
const checkIfClosed = (store) => (nextState) => {
  const { params } = nextState;
  const { entities } = store.getState();
  const { revisions } = entities;
  if (_.isEmpty(revisions)) {
    store.dispatch(loadRevision(params)).then(() => {
      const { entities: updatedEntities } = store.getState();
      const revision = currentRevision(updatedEntities, params.revisionSeq);
      if (revision.closed_at) {
        // can't use replace here since it seems react router calls it again
        // if the route matches a child component; so if you enter a path that
        // is assigned to a child of Home, doing a replace here get's overwritten
        // by react router.
        browserHistory.push(Links.home(params));
      }
    });
  }
};

const checkIfCanHref = (store) => (nextState, replace, cb) => {
  const { fourfour } = nextState.params;
  const view = store.getState().entities.views[fourfour] || {};
  const displayType = _.toLower(view.displayType);
  const { viewType } = view;
  const canHref = displayType === 'draft' || viewType === 'href';

  if (!canHref) {
    replace(Links.sources(nextState.params));
  }

  if (_.isFunction(cb)) {
    cb();
  }
};

const checkIfViewHasBlob = (store) => (nextState, replace, cb) => {
  const { fourfour } = nextState.params;
  const view = store.getState().entities.views[fourfour];

  if (view.viewType !== 'blobby') {
    const newPath = Links.home(nextState.params);
    replace(newPath);
  }

  if (_.isFunction(cb)) {
    cb();
  }
};

// can't visit the other upload pages if you're editing an href view
const checkIfCanUploadData = (store) => (nextState, replace, cb) => {
  const { fourfour } = nextState.params;
  const viewType = _.toLower(store.getState().entities.views[fourfour].viewType);

  if (viewType === 'href') {
    replace(Links.hrefSource(nextState.params));
  }

  if (_.isFunction(cb)) {
    cb();
  }
};

const checkIfViewer = (store) => (nextState, replace, cb) => {
  const isViewer = ModeGrantActions.isViewer(_.get(store.getState(), 'ui.modeGrant.grant', ''));
  if (isViewer) {
    replace(Links.home(nextState.params));
  }

  if (_.isFunction(cb)) {
    cb();
  }
};

const datasetLicenses = window.initialState?.datasetLicenses || [];

export default function rootRoute(store) {
  // If we upgrade to react-router 4 we can have a non-insane route hierarchy for the showOutputSchema
  // page: https://github.com/ReactTraining/react-router/issues/4105#issuecomment-289195202
  return (
    <Route
      path="/(:locale/):category/:name/:fourfour/revisions/:revisionSeq"
      onEnter={checkIfClosed(store)}
      component={Home}
    >
      <IndexRoute component={ShowRevision} />

      <Route
        path="metadata/:outputSchemaId/columns"
        component={ManageColumnMetadata}
        onEnter={checkSchemaStatus(store)}
      />
      <Route
        path="metadata/dataset"
        component={(props) => (
          <ManageAssetMetadata
            datasetLicenses={datasetLicenses}
            isModal={true}
            onClose={() => {
              browserHistory.push(Links.revisionBase(props.params));
            }}
          />
        )}
      />
      <Route path="metadata/columns" component={ManageColumnMetadata} />
      <Route
        path="metadata"
        component={(props) => (
          <ManageAssetMetadata
            datasetLicenses={datasetLicenses}
            isModal={true}
            onClose={() => {
              browserHistory.push(Links.revisionBase(props.params));
            }}
          />
        )}
      />

      <Route path="sources" component={ShowSource} onEnter={checkIfViewer(store)}>
        <IndexRoute component={DsmuiDragDropUpload} onEnter={checkIfCanUploadData(store)} />
        <Route path="url" component={URLSource} onEnter={checkIfCanUploadData(store)} />
        <Route path="href" component={HrefForm} onEnter={checkIfCanHref(store)} />
        <Route path="agent" component={AgentList} onEnter={checkIfCanUploadData(store)} />
        <Route
          path="agent/:agent/:nstype/:nsname"
          component={AgentNamespace}
          onEnter={checkIfCanUploadData(store)}
        />
      </Route>
      <Route
        path="sources/:sourceId/schemas/:inputSchemaId/output/:outputSchemaId"
        component={ShowOutputSchema}
      >
        <IndexRoute component={TablePane} />
        <Route path="page/:pageNo" component={TablePane} />
        <Route path="parse_options" component={ParseOptionsPane} onEnter={checkIfViewer(store)} />
        <Route path="mismatch" component={SchemaMismatchPane} onEnter={checkIfViewer(store)} />

        <Route path="georeference" component={GeocodeShortcutPane} onEnter={checkIfViewer(store)}>
          <Route path="column_errors/:errorsTransformId" component={GeocodeShortcutPane}>
            <Route path="page/:pageNo" component={GeocodeShortcutPane} />
          </Route>
          <Route path="page/:pageNo" component={GeocodeShortcutPane} />
        </Route>
        {/* // the reason these are two separate routes instead of just having
        // the presence of the outputColumnId mean you're editing (way simpler)
        // is because react router is shit and will highlight <Link> elements even
        // if they are a substring of the present route. neat. */}
        <Route
          path="edit_georeference/:outputColumnId"
          component={GeocodeShortcutPane}
          onEnter={checkIfViewer(store)}
        >
          <Route path="column_errors/:errorsTransformId" component={GeocodeShortcutPane}>
            <Route path="page/:pageNo" component={GeocodeShortcutPane} />
          </Route>
          <Route path="page/:pageNo" component={GeocodeShortcutPane} />
        </Route>

        <Route path="editor/:outputColumnId" component={TransformColumnPane} onEnter={checkIfViewer(store)}>
          <Route path="column_errors/:errorsTransformId" component={TransformColumnPane}>
            <Route path="page/:pageNo" component={TransformColumnPane} />
          </Route>
          <Route path="page/:pageNo" component={TransformColumnPane} />
        </Route>

        <Route path="add_col" component={AddColPane} onEnter={checkIfViewer(store)} />

        <Route path="column_errors(/:errorsTransformId)" component={TablePane}>
          <Route path="page/:pageNo" component={TablePane} />
        </Route>

        <Route path="row_errors" component={TablePane}>
          <Route path="page/:pageNo" component={TablePane} />
        </Route>
      </Route>
      <Route path="sources/preview" component={ShowBlobPreviewFromView} onEnter={checkIfViewHasBlob(store)} />
      <Route path="sources/:sourceId/preview" component={ShowBlobPreviewFromSource} />
      <Route path="soql" component={EditSoQLQuery} />
      <Route path="*" component={NoMatch} />
    </Route>
  );
}
