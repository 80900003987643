/* eslint react/prop-types: 0 */
import PropTypes from 'prop-types';
import React from 'react';
import TransformStatus from 'datasetManagementUI/components/TransformStatus/TransformStatus';
import TableBody from 'datasetManagementUI/containers/TableBodyContainer';
import * as DisplayState from 'datasetManagementUI/lib/displayState';
import * as Links from '../../links/links';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.geocode_shortcut' });

const ColumnPreview = ({
  outputSchema,
  anySelected,
  isPreviewable,
  entities,
  params,
  inputSchema,
  outputColumn,
  displayState,
  onPreview,
  enableAddColumn,
  onAddColumn,
  isEdit
}) => {
  let body;

  if (!anySelected) {
    body = (
      <div className="no-column-yet">
        <div>
          <p className="begin-georeferencing">
            {' '}{t('begin_georeferencing')}{' '}
          </p>
        </div>
      </div>
    );
  } else if (!isPreviewable) {
    body = (
      <div className="no-column-yet">
        <button onClick={onPreview} className="btn btn-simple">
          {t('run_geocoding')}
        </button>
        <div class="run-or-add-help">
          {t(isEdit ? 'run_or_update_help' : 'run_or_add_help')}
        </div>
      </div>
    );
  } else {
    const getErrorLinkPath = () => {
      return DisplayState.inErrorMode(displayState, outputColumn.transform)
        ? Links.geocodeShortcut(params)
        : Links.geocodeShortcutErrors(params, outputColumn.transform.id);
    };

    body = (
      <div>
        <div className="column-preview">
          <table>
            <thead className="column-header">
              <tr>
                <th>
                  <span className="col-name" title={outputColumn.display_name}>
                    {outputColumn.display_name}
                  </span>
                </th>
                <TransformStatus
                  outputSchema={outputSchema}
                  outputColumn={outputColumn}
                  transform={outputColumn.transform}
                  key={outputColumn.id}
                  params={params}
                  isIgnored={false}
                  displayState={displayState}
                  shortcuts={[]}
                  flyouts={false}
                  columnErrorLinkPath={getErrorLinkPath()}
                  totalRows={inputSchema.total_rows} />
              </tr>
            </thead>
            <TableBody
              entities={entities}
              columns={[outputColumn]}
              displayState={displayState}
              inputSchemaId={inputSchema.id} />
          </table>
        </div>
      </div>
    );
  }
  return (
    <div className="column-preview-wrap">
      <h6> {t('column_preview')} </h6>
      {body}
      <button
        className='btn btn-primary add-column-btn'
        disabled={!enableAddColumn}
        onClick={onAddColumn}>
        {t(isEdit ? 'update_column' : 'add_column')}
      </button>
    </div>
  );
};

ColumnPreview.propTypes = {
  displayState: PropTypes.object.isRequired,
  inputSchema: PropTypes.object.isRequired,
  outputSchema: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  entities: PropTypes.object.isRequired,
  onPreview: PropTypes.func.isRequired,
  outputColumn: PropTypes.object,
  isPreviewable: PropTypes.bool.isRequired,
  anySelected: PropTypes.bool.isRequired,
  enableAddColumn: PropTypes.bool.isRequired,
  onAddColumn: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired
};

export default ColumnPreview;
