import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BlobPreview, BlobDownload } from 'common/components/BlobbyDatasets';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import BlobFileInfo from 'datasetManagementUI/components/BlobFileInfo/BlobFileInfo';
import FlashMessage from 'datasetManagementUI/containers/FlashMessageContainer';
import SourceBreadcrumbs from 'datasetManagementUI/containers/SourceBreadcrumbsContainer';
import SaveBlobOrSchemaButton from 'datasetManagementUI/pages/ShowOutputSchema/SaveBlobOrSchemaButton';

export class ShowBlobPreview extends Component {
  render() {
    const {
      blobPreviewProps,
      blobDownloadProps,
      blobFileInfoProps,
      saveBlobButtonProps,
      goHome,
      isSourceInProgress,
      isSourceFailedButWillBeBlobbified
    } = this.props;
    return (
      <div id="show-blob-preview">
        <Modal fullScreen onDismiss={goHome}>
          <ModalHeader onDismiss={goHome}>
            <SourceBreadcrumbs atShowBlobPreview />
          </ModalHeader>
          <ModalContent className="blobPreviewContainer">
            {
              isSourceInProgress || isSourceFailedButWillBeBlobbified ?
                <span className="spinner-default"></span> :
                <>
                  <FlashMessage />
                    <div className="leftSection">
                      <BlobPreview
                        isPreviewable={blobPreviewProps.isPreviewable}
                        previewUrl={blobPreviewProps.previewUrl}
                        previewType={blobPreviewProps.previewType}
                        blobName={blobPreviewProps.blobFilename} />
                      <BlobDownload
                        showDownloadSection={blobDownloadProps.showDownloadSection}
                        scanResult={blobDownloadProps.scanResult}
                        scanResultUrl={blobDownloadProps.scanResultUrl}
                        blobFilename={blobDownloadProps.blobFilename}
                        downloadLink={blobDownloadProps.downloadLink} />
                    </div>
                    <div className="rightSection">
                      <BlobFileInfo
                        contentType={blobFileInfoProps.contentType}
                        fileSize={blobFileInfoProps.fileSize}
                        sourcesLink={blobFileInfoProps.sourcesLink} />
                    </div>
                  </>
            }
          </ModalContent>
          <ModalFooter>
            {isSourceInProgress ? null : <SaveBlobOrSchemaButton
              autosaved={saveBlobButtonProps.autosaved}
              params={saveBlobButtonProps.params}
              blobId={saveBlobButtonProps.blobId}
              goToRevisionBase={goHome}
              />}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

ShowBlobPreview.propTypes = {
  goHome: PropTypes.func.isRequired,
  blobPreviewProps: PropTypes.object,
  blobFileInfoProps: PropTypes.object,
  blobDownloadProps: PropTypes.object,
  saveBlobButtonProps: PropTypes.object,
  isSourceInProgress: PropTypes.bool,
  isSourceFailedButWillBeBlobbified: PropTypes.bool
};

export default ShowBlobPreview;
