import * as React from 'react';
import * as Rights from 'common/rights';
import get from 'lodash/get';
import { browserHistory } from 'react-router';
import * as Links from 'datasetManagementUI/links/links';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
//@ts-expect-error
import Namespace from './Namespace';
import Connections from './Connections';
import GatewayOnlineOffline from 'common/components/GatewayOnlineOffline';
import PluginIcon from 'common/components/PluginIcon';
import { fetchTranslation } from 'common/locale';
import { CombinedProps } from 'datasetManagementUI/containers/AgentNamespaceContainer';
const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.connection_agent');

class AgentNamespace extends React.Component<CombinedProps, any> {
  UNSAFE_componentWillMount() {
    // eslint-disable-line camelcase
    const ns = this.props.namespace;
    if (!ns || !ns.plugin) this.props.getPlugins();
  }

  render() {
    const {
      agent,
      namespace,
      createSource,
      startSource,
      listSources,
      params,
      showPluginHelp,
      showAvailablePlugins,
      showProvisionAgent,
      plugins
    } = this.props;

    if (!agent) return null;

    let onlineOrOffline = 'agent-never-online';
    if (agent.went_offline_at) {
      onlineOrOffline = 'agent-offline';
    } else if (agent.went_online_at) {
      onlineOrOffline = 'agent-online';
    }

    let pluginDisplayName;
    if (namespace && namespace.plugin) {
      pluginDisplayName = <span>{namespace.plugin.display_name}</span>;
    } else {
      pluginDisplayName = <span className="spinner-default" />;
    }

    const userRights = get(window, 'socrata.currentUser.rights');
    const canAddAgents =
      userRights.includes(Rights.MANAGE_GATEWAYS) || userRights.includes(Rights.CREATE_GATEWAY_AGENTS);

    return (
      <Connections
        title={t('choose_datasource')}
        showAvailablePlugins={showAvailablePlugins}
        showPluginHelp={showPluginHelp}
        showProvisionAgent={showProvisionAgent}
        plugins={plugins}
        canProvisionAgent={canAddAgents}
      >
        <div className="well">
          <a onClick={() => browserHistory.push(Links.agentsSource(params))}>
            <SocrataIcon name={IconName.ArrowLeft} />
            {t('back_to_agents')}
          </a>
        </div>
        <div className="well">
          <div className={`agent-namespace ${onlineOrOffline}`}>
            <div className="agent-head namespace-attributes">
              <div className="head-group">
                <PluginIcon plugin={namespace && namespace.plugin} />
                <div className="attributes">
                  <h4>{namespace && namespace.name}</h4>
                  <div className="attribute">
                    <label>{t('type')}</label>
                    {pluginDisplayName}
                  </div>
                  <div className="attribute">
                    <label>{t('agent_name')}</label>
                    <span>{agent.name}</span>
                  </div>
                </div>
              </div>
              <GatewayOnlineOffline agent={agent} />
            </div>

            <Namespace
              isOnline={!!agent.went_online_at}
              namespace={namespace}
              createSource={createSource}
              startSource={startSource}
              listSources={listSources}
            />
          </div>
        </div>
      </Connections>
    );
  }
}

export default AgentNamespace;
