import Button, { SIZES, VARIANTS } from 'common/components/Button';
import { defaultMatcher } from 'common/components/SoQLDocs';
import FunctionDoc from 'common/components/SoQLDocs//FunctionDoc';
import ColumnDoc from 'common/components/SoQLDocs/ColumnDoc';
import formatString from 'common/js_utils/formatString';
import { fetchTranslation } from 'common/locale';
import { isCompilationFailed, isCompilationSucceeded } from 'common/types/compiler';
import { PhxChannel } from 'common/types/dsmapi';
import { OutputColumn } from 'common/types/dsmapiSchemas';
import { FunSpec, SoQLType } from 'common/types/soql';
import { TransformColumnProps } from 'datasetManagementUI/containers/TransformColumnContainer';
import ProgressBar from 'datasetManagementUI/components/ProgressBar/ProgressBar';
import _ from 'lodash';
import * as DisplayState from 'datasetManagementUI/lib/displayState';
import * as React from 'react';
import SoQLResults from 'datasetManagementUI/containers/SoQLResultsContainer';
import {
  buildNewOutputColumn,
  cloneOutputColumn
} from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';
import { DEFAULT_WIDTH } from 'datasetManagementUI/components/Table/Table';
import DSMUIIcon from '../DSMUIIcon';
//@ts-expect-error
import ColumnConfig from './ColumnConfig';
import { SoQLEditorWrapper } from './SoQLEditorWrapper';
//@ts-expect-error
import TransformSelect from './TransformSelect';
import { Option, some, none } from 'ts-option';
import TableCell from 'common/components/DatasetTable/cell/TableCell';
import TransformStatus from '../TransformStatus/TransformStatus';

const t = (k: string, scope = 'dataset_management_ui.transform_column') => fetchTranslation(k, scope);

interface Config {
  columns: OutputColumn[];
}
interface Snippet {
  id: number;
  name: string;
  transform_expr: string;
}

const renderFunctionDoc = (name: string, impls: FunSpec[]) => (abbreviated: boolean) =>
  <FunctionDoc name={name} impls={impls} abbreviated={abbreviated} />;

const renderColumnDoc = (inputColumn: { fieldName: string; soqlType: SoQLType }) => (abbreviated: boolean) =>
  <ColumnDoc column={inputColumn} abbreviated={abbreviated} />;

const RequestError = ({
  requestErrorMessage,
  onClose
}: {
  requestErrorMessage: string;
  onClose: () => void;
}) => (
  <div className="compiler-result compiler-error">
    {requestErrorMessage}
    <DSMUIIcon className="close-snippet-error-msg" name="close-2" onIconClick={onClose} />
  </div>
);

const TypeChangeError = ({ existingType, newType }: { existingType: SoQLType; newType: SoQLType }) => (
  <div className="compiler-result compiler-error">
    {formatString(t('invalid_type_change'), {
      existingType,
      newType
    })}
  </div>
);

interface State {
  snippetName: null | string;
  channel: null | PhxChannel;
  recentSnippets: null | never;
  searchResultSnippets: null | never;
  requestError: null | string;
  isSaving: boolean;
  configError: boolean;
}

class TransformColumn extends React.Component<TransformColumnProps, State> {
  constructor(props: TransformColumnProps) {
    super(props);

    this.state = {
      snippetName: null,
      channel: null,
      recentSnippets: null,
      searchResultSnippets: null,
      requestError: null,
      isSaving: false,
      configError: false
    };

    this.searchTransformSnippets = _.debounce(this.searchTransformSnippets.bind(this), 300);
  }

  UNSAFE_componentWillMount() {
    const compilerInputSchemaId = _.get(this.props, 'compiler.inputSchema.id');
    const inputSchemaId = _.get(this.props, 'inputSchema.id');

    if (!this.props.compiler || compilerInputSchemaId !== inputSchemaId) {
      this.props.addCompiler(this.props.inputSchema);
    }

    this.joinChannel();
  }

  UNSAFE_componentWillReceiveProps(props: TransformColumnProps) {
    // eslint-disable-line camelcase
    const compilerInputSchemaId = _.get(this.props, 'compiler.inputSchema.id');
    const inputSchemaId = _.get(this.props, 'inputSchema.id');

    if (props.compiler && compilerInputSchemaId !== inputSchemaId) {
      props.addCompiler(props.inputSchema);
    }

    if (!props.config && props.source.source_type.type === 'view') {
      // just using an error flag here because this shouldn't happen, and
      // if it does it's not actionable by the user
      props.getConfig().catch(() => this.setState({ configError: true }));
    }
  }

  componentWillUnmount() {
    this.leaveChannel();
    this.props.removeCompiler();
  }

  getExpression() {
    return this.props.compiler && !_.isNil(this.props.compiler.expression)
      ? this.props.compiler.expression
      : this.props.transform.transform_expr;
  }

  setRequestErrorMessage(errorMessage: string | null = null) {
    if (errorMessage) {
      errorMessage = _.replace(errorMessage, '{name}', `"${this.state.snippetName}"`);
      this.setState({ requestError: errorMessage });
    } else {
      this.setState({ requestError: null });
    }
  }

  getTransformSnippet(id: number): Snippet | undefined {
    const idMatch = (snip: Snippet) => snip.id.toString() === id.toString();
    const recentSnips = this.state.recentSnippets;
    const resultSnips = this.state.searchResultSnippets;
    return _.find(recentSnips, idMatch) || _.find(resultSnips, idMatch);
  }

  joinChannel() {
    const channel = this.props.snippetsChannel();
    channel.join();
    this.setState({ channel });
  }

  leaveChannel() {
    if (this.state.channel && typeof this.state.channel.leave === 'function') {
      this.state.channel.leave();
    }
  }

  genExpressionCompleter() {
    const columnLikes = this.props.inputColumns.map((ic) => {
      return {
        fieldName: ic.field_name,
        displayName: ic.display_name,
        soqlType: ic.soql_type
      };
    });

    return defaultMatcher(this.props.scope, columnLikes, renderFunctionDoc, renderColumnDoc);
  }

  compilationFailed() {
    if (this.props.compiler) {
      return isCompilationFailed(this.props.compiler.result);
    }
    return false;
  }

  clearRequestError = () => {
    this.setRequestErrorMessage(null);
  };

  evaluateExpr = () => {
    if (!this.compilationFailed() && this.props.compiler) {
      const expr = this.props.compiler.expression;
      if (expr !== null) {
        this.props
          .newOutputSchema(
            this.props.inputSchema,
            expr,
            this.genDesiredColumns(expr),
            this.props.outputSchema
          )
          .catch((error) => {
            if (error && error.message) {
              this.setRequestErrorMessage(error.message);
            }
          });
      }
    }
  };

  genDesiredColumns = (expr: string) => {
    return this.props.outputColumns.map((oc) => {
      if (this.props.compiler && oc.id === this.props.outputColumn.id) {
        // this is our target column - change the expr
        return buildNewOutputColumn(oc, () => expr);
      } else {
        // these ones are unchanged
        return cloneOutputColumn(oc);
      }
    });
  };

  searchTransformSnippets(text: string) {
    if (this.state.channel) {
      this.state.channel
        .push('search', { name: text })
        .receive('ok', (msg) => {
          if (this.state.snippetName) {
            this.setState({ searchResultSnippets: msg.resource || [] });
          }
        })
        .receive('error', (errMsg) => {
          console.error('searchTransformSnippets error:', errMsg);
        });
    }
  }

  handleEditorChange = (newCode: string) => {
    // If the view is unloaded, we don't have any data available to generate a preview with.
    const shouldGeneratePreview = !this.props.unloadedViewSource;

    if (this.props.compiler) {
      this.props.compileExpression(this.props.compiler, newCode, shouldGeneratePreview);
    }
  };

  deleteTransformSnippet = ({ id }: { id: number }) => {
    if (this.state.channel) {
      this.state.channel
        .push(`delete:${id}`)
        .receive('ok', () => {
          this.fetchRecentTransformSnippets();

          // if viewing search results, update results
          if (this.state.searchResultSnippets && this.state.snippetName) {
            this.searchTransformSnippets(this.state.snippetName);
          }
        })
        .receive('error', (errMsg) => {
          console.error('deleteTransformSnippet error:', errMsg);
          if (errMsg.message) {
            this.setRequestErrorMessage(errMsg.message);
          }
        });
    }
  };

  fetchRecentTransformSnippets = () => {
    if (this.state.channel) {
      this.state.channel
        .push('fetch_recent')
        .receive('ok', (msg) => {
          this.setState({ recentSnippets: msg.resource || [] });
        })
        .receive('error', (errMsg) => {
          console.error('fetchRecentTransformSnippets error:', errMsg);
        });
    }
  };

  clearSnippets = () => {
    this.setState({ recentSnippets: null });
    this.setState({ searchResultSnippets: null });
  };

  handleSaveSnippet = () => {
    if (this.state.channel) {
      this.clearRequestError();
      this.clearSnippets();

      const snippetName = _.trim(this.state.snippetName || '').replace(/\s+/, ' ');
      this.setState({ snippetName: snippetName });
      if (!snippetName) {
        this.setRequestErrorMessage(t('missing_snippet_name'));
        return console.error('missing snippetName');
      }

      this.setState({ isSaving: true });

      this.state.channel
        .push('upsert', {
          transform_expr: this.getExpression(),
          name: this.state.snippetName
        })
        .receive('ok', () => {
          this.setState({ isSaving: false });
        })
        .receive('error', (errMsg) => {
          console.error('handleSaveSnippet error:', errMsg);
          if (errMsg.message) {
            this.setRequestErrorMessage(errMsg.message);
          }
        });
    }
  };

  handleSnippetNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const text = (evt.target.value || '').replace(/\s+/, ' ');
    this.setState({ snippetName: text });
    if (_.trim(text)) {
      this.searchTransformSnippets(text);
    } else {
      this.clearSnippets();
    }
  };

  handleSelectTransformSnippet = ({ value: snippetId }: { value: number }) => {
    const transformSnippet = this.getTransformSnippet(snippetId);
    if (!transformSnippet) {
      return;
    }
    this.setState({ snippetName: transformSnippet.name });
    _.defer(() => this.handleEditorChange(transformSnippet.transform_expr));
  };

  genTransformSnippetSelectProps = () => {
    return {
      snippets: this.state.searchResultSnippets || this.state.recentSnippets,
      dropdownHeader: this.state.searchResultSnippets ? t('search_result') : t('recent_snippets'),
      snippetName: this.state.snippetName,
      onSnippetNameChange: this.handleSnippetNameChange,
      onSelect: this.handleSelectTransformSnippet,
      onCloseDropdown: () => _.defer(this.clearSnippets),
      onOpenDropdown: this.fetchRecentTransformSnippets,
      onDelete: this.deleteTransformSnippet
    };
  };

  hasTypeChangeError() {
    // No type change if we can't even compile it...
    if (this.compilationFailed()) return false;
    // If we can change the type, then there's no error
    if (this.props.canChangeType) return false;

    // is the existing transform type different from the type dsmapi gave us back
    // from compiling the expression? if so, the user is attempting a type change.
    const attemptingTypeChange =
      this.props.compiler &&
      isCompilationSucceeded(this.props.compiler.result) &&
      this.props.compiler.result.parsed.result_type !== this.props.transform.output_soql_type;

    // at this point we know we're not allowed to change the type, so if an attempt is made
    // that's an error
    return !!attemptingTypeChange;
  }

  getPreview = (): Option<JSX.Element> => {
    const preview = this.props.preview;
    if (preview && preview.type === 'preview_available') {
      // If the compiler's current expression is the same as the transform, then the buffer
      // hasn't changed. We have no need to show a preview, just show the actual results.
      if (this.props.transform.transform_expr === this.props.compiler?.expression) {
        return none;
      }

      return some(
        <div className="column-preview column-header">
          <table>
            <thead>
              <tr>
                <th>
                  <span className="col-name" title={this.props.outputColumn.display_name}>
                    {this.props.outputColumn.display_name}
                  </span>
                </th>
                <TransformStatus
                  outputSchema={this.props.outputSchema}
                  outputColumn={this.props.outputColumn}
                  transform={this.props.transform}
                  params={this.props.params}
                  isDropping={false}
                  displayState={DisplayState.normal(0, this.props.outputSchema.id)}
                  totalRows={preview.results.length}
                  shortcuts={[]}
                  flyouts={false}
                  unloadedViewSource={false}
                  editMode={false}
                  width={DEFAULT_WIDTH}
                  isValidatingRowId={false}
                  preview={this.evaluateExpr}
                />
              </tr>
            </thead>
            <tbody className="tableBody" tabIndex={0}>
              {preview.results.map((cell, idx) => {
                return (
                  <tr key={`row-${idx}`}>
                    <TableCell
                      width={DEFAULT_WIDTH}
                      isDropping={false}
                      isHidden={false}
                      key={`preview-${idx}`}
                      cell={cell}
                      format={this.props.outputColumn.format}
                      failed={false}
                      updateCell={_.noop}
                      isEditing={false}
                      canEdit={false}
                      editCell={_.noop}
                      type={preview.soqlType}
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return none;
  };

  render() {
    const { redirectToOutputSchema, outputSchema } = this.props;

    let evaluateButton;
    if (this.compilationFailed()) {
      evaluateButton = (
        <button type="button" className="evaluate-btn btn-primary btn btn-sm btn-error">
          {t('compile_fail')}
          <DSMUIIcon name={'failed'} />
        </button>
      );
    } else if (this.hasTypeChangeError()) {
      evaluateButton = (
        <button type="button" className="evaluate-btn btn-primary btn btn-sm btn-error">
          {t('invalid_type')}
          <DSMUIIcon name={'failed'} />
        </button>
      );
    } else {
      evaluateButton = (
        <button type="button" className="evaluate-btn btn-primary btn btn-sm" onClick={this.evaluateExpr}>
          {t('run_transform')}
          <DSMUIIcon name={'play'} />
        </button>
      );
    }

    const saveSnippetButton = (
      <Button
        disabled={!this.state.snippetName}
        variant={VARIANTS.ALTERNATE_2}
        size={SIZES.SMALL}
        onClick={this.handleSaveSnippet}
      >
        {t('save_snippet')} {this.state.isSaving && <span className="spinner-default spinner-dark" />}
      </Button>
    );

    return (
      <div className="transform-col-container">
        <div className="transform-col-editor">
          <div className="button-bar">
            <form>
              <TransformSelect {...this.genTransformSnippetSelectProps()} />
              {saveSnippetButton}
              {evaluateButton}
            </form>
            <button
              onClick={() => redirectToOutputSchema(outputSchema.id)}
              className="btn btn-link back-to-preview-btn"
            >
              <span className="icon-arrow-left"></span>
              {t('back_to_data_preview', 'dataset_management_ui.show_output_schema')}
            </button>
          </div>

          <div className="transform-builder">
            <div className="composition-pane">
              {this.state.requestError && (
                <RequestError
                  requestErrorMessage={this.state.requestError}
                  onClose={this.clearRequestError}
                />
              )}
              {this.hasTypeChangeError() && (
                <TypeChangeError
                  existingType={this.props.transform.output_soql_type}
                  newType={_.get(this.props, 'compiler.result.parsed.result_type')}
                />
              )}
              {this.state.configError && <div className="alert error">{t('config_fail')}</div>}
              {this.props.config && !this.state.configError ? (
                <ColumnConfig config={this.props.config} outputColumn={this.props.outputColumn} />
              ) : null}
              {this.props.compiler && (
                <SoQLEditorWrapper
                  expression={this.getExpression()}
                  scope={this.props.scope}
                  compiler={this.props.compiler}
                  completeExpression={this.genExpressionCompleter()}
                  onChange={this.handleEditorChange}
                  // The whole type changing logic is encapsulated a level above the
                  // editor - we want to tell the editor to not show anything even if
                  // the expression compiles successfully, because in this case we consider
                  // a type change an invalid state, so we'll show an error.
                  hideCompilationResult={this.hasTypeChangeError()}
                />
              )}
            </div>
            <div className="results-pane">
              {this.getPreview().getOrElse(() => (
                <SoQLResults
                  params={this.props.params}
                  inputSchema={this.props.inputSchema}
                  outputSchema={this.props.outputSchema}
                  outputColumn={this.props.outputColumn}
                  location={this.props.location}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TransformColumn;
