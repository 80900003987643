import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router';
import Dropdown from 'common/components/Dropdown';
import { attachTo } from 'common/components/legacy';
import SocrataIcon from 'common/components/SocrataIcon';
import formatString from 'common/js_utils/formatString';
import PropTypes from 'prop-types';
import { isColumnRef, traverse } from 'datasetManagementUI/lib/ast';
import Modes from 'datasetManagementUI/lib/modes';
import Pager from 'common/components/Pager';
import I18n from 'common/i18n';

const t = (k, scope = 'dataset_management_ui.show_output_schema.column_mismatch') => I18n.t(k, { scope });

export const EMPTY_REFERENCE = 'EMPTY_REFERENCE';
export const REMOVED_REFERENCE = 'REMOVED_REFERENCE';

// Each column has a dropdown with all other columns as the options, so
// the number of dropdown elements on the page is the square of the number
// of columns in the dataset, so we need to page this component
// so it doesn't totally crash the browser on wide datasets
const PAGE_SIZE = 30;

const SpookyOption = ({ title }) => (
  <span className="picklist-title remove-permanently">{title}</span>
);

SpookyOption.propTypes = {
  title: PropTypes.string.isRequired
};

const ColumnReference = ({
  reference,
  newReference,
  isRemoved,
  effectedColumns,
  gotoTransformEditor,
  dropColumn,
  unDropColumn,
  gotoGeocodeColumnEditor,
  referenceable,
  setMapping,
  canDropColumn
}) => {
  let klasses = 'column-ref-row';
  let newColumnRefClasses = 'input-column-dropdown';
  let oldInput = (<p>{reference.fieldName}</p>);
  let newMappingStatus = null;

  let referencedBy;

  if (effectedColumns.length === 1) {
    const name = effectedColumns[0].display_name;
    referencedBy = formatString(t('required_to_make.singular'), { column: name });
  } else {
    const names = effectedColumns.map(c => c.display_name).join(', ');
    referencedBy = formatString(t('required_to_make.plural'), { columns: names });
  }

  if (!reference.refersTo && _.isUndefined(newReference)) {
    klasses = 'column-ref-row invalid-reference';

    oldInput = (
      <div>
        {oldInput}
        <p className="status">{referencedBy}</p>
      </div>
    );
    newMappingStatus = (<p className="warning-text">{t('choose_new_mapping')}</p>);
    newColumnRefClasses = 'input-column-dropdown new-import-column new-import-column-warning';
  } else if (isRemoved) {
    klasses = 'column-ref-row removed-row';
    oldInput = (
      <div>
        {oldInput}
        <p className="status">{referencedBy}</p>
      </div>
    );
    newMappingStatus = (<p className="error-text">{t('reference_removed')}</p>);
  } else if (newReference) {
    klasses = 'column-ref-row mapped-row';
    newMappingStatus = (<p className="success-text">{t('successfully_mapped')}</p>);
    newColumnRefClasses = 'input-column-dropdown new-import-column new-import-column-success';
  } else {
    newColumnRefClasses = 'input-column-dropdown new-import-column';
  }

  // This is a special case for EN-27032
  // Story: People set up their geocoded location column through Imports 2, and now when they edit
  // in DSMP, they upload their same file, and get a schema mismatch looking for that column because
  // it doesn't exist in their source file. So in this case, we want to provide a link that links them
  // to the georef modal in the edit state editing this column which failed.
  // So in this special case, where there is only one column which has failed, as it's looking
  // for a column called
  // so in the case where there is a *single* output column which *should be* a location type,
  // show this extra link which takes them to the geocode UI
  let locationColumnHint = null;
  if (effectedColumns.length === 1 && effectedColumns[0].transform.output_soql_type === 'location') {
    const outputColumn = effectedColumns[0];
    locationColumnHint = (
      <div>
        <p className="status">{t('location_column_explainer')}</p>
        <Link
          className="go-elsewhere status"
          onClick={() => gotoGeocodeColumnEditor(outputColumn)}>
          {t('reconfigure_location_geocoding')}
        </Link>
      </div>
    );
  }

  let fixOptions = [];
  if (canDropColumn) {
    fixOptions.push((isRemoved ?
      { title: t('unremove'), value: unDropColumn } :
      { title: t('remove'), value: dropColumn, render: SpookyOption }
    ));
  }

  fixOptions = _.reverse(effectedColumns.reduce((acc, oc) => {
    return [...acc, {
      title: formatString(t('change_transform_of'), { name: oc.display_name }),
      value: () => gotoTransformEditor(oc)
    }];
  }, fixOptions));

  const selectedValue = newReference || (reference.refersTo ? reference.refersTo.field_name : 'disabled');

  return (
    <tr key={`remap-${reference.fieldName}`} className={klasses}>
      <td>
        <div className="old-input-name">
          {oldInput}
        </div>
      </td>
      <td className="is-now">
        <span className="is-now">{t('is_now')}</span>
      </td>
      <td className={newColumnRefClasses}>
        {newMappingStatus}
        <select
          className="mimic-styleguide-dropdown"
          value={selectedValue}
          onChange={(event) => setMapping(event.currentTarget.value)}>
          <option value="disabled" disabled selected={!!selectedValue}>{t('select_a_mapping')}</option>
          {referenceable.map(r => (
            <option key={r.value} value={r.value}>{r.title}</option>
          ))}
        </select>
        {locationColumnHint}
      </td>
      <td className="show-hide-fix-options">
        <Dropdown
          options={fixOptions}
          showOptionsBelowHandle
          onSelection={(selected) => selected.value()}
          placeholder={() => {
            return (
              <button className="dropdown-btn btn btn-xs btn-simple socrata-icon-kebab" />
            );
          }} />

      </td>
    </tr>
  );
};

ColumnReference.propTypes = {
  reference: PropTypes.object.isRequired,
  newReference: PropTypes.string,
  isRemoved: PropTypes.bool.isRequired,
  effectedColumns: PropTypes.array.isRequired,
  gotoTransformEditor: PropTypes.func.isRequired,
  dropColumn: PropTypes.func.isRequired,
  unDropColumn: PropTypes.func.isRequired,
  gotoGeocodeColumnEditor: PropTypes.func.isRequired,
  referenceable: PropTypes.array.isRequired,
  setMapping: PropTypes.func.isRequired,
  canDropColumn: PropTypes.bool.isRequired
};

const FLYOUT_ID = 'ordering-explanation-flyout';
const OrderingFlyout = () => (
  <div id={FLYOUT_ID} className="flyout flyout-hidden">
    <section className="flyout-content">
      {t('map_via_ordering_explanation')}
    </section>
  </div>
);

const outputColumnReferencesFieldName = (oc, fieldName) => {
  return traverse(oc.transform.parsed_expr, false, (node, acc) => {
    if (isColumnRef(node) && node.value === fieldName) {
      return true;
    }
    return acc;
  });
};

class SchemaMismatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingAllColumns: true,
      previousInputColumns: null,
      mapViaOrdering: false,
      showParseOptions: false,
      currentPage: 1
    };
    this.setShowAllColumns = this.setShowAllColumns.bind(this);
    this.toggleMapViaOrdering = this.toggleMapViaOrdering.bind(this);
    this.toggleParseOptionsExplanation = this.toggleParseOptionsExplanation.bind(this);
    this.setMapping = this.setMapping.bind(this);
    this.dropColumn = this.dropColumn.bind(this);
    this.unDropColumn = this.unDropColumn.bind(this);
  }

  componentDidMount() {
    this.props.getGuidance().then((resp) => {
      if (resp.previous_input_schema && resp.previous_input_schema.input_columns) {
        this.setState({
          previousInputColumns: _.sortBy(resp.previous_input_schema.input_columns, ic => ic.position)
        });
      }
    });
    this.attachFlyouts();
  }

  componentDidUpdate() {
    this.attachFlyouts();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(nextProps.params, this.props.params) ||
      !_.isEqual(nextState, this.state) ||
      !_.isEqual(nextProps.mappings, this.props.mappings)
    );
  }

  getOutputColumnsReferencing(fieldName) {
    return _.filter(this.props.outputColumns, (oc) => {
      return outputColumnReferencesFieldName(oc, fieldName);
    });
  }

  setShowAllColumns(isShowingAllColumns) {
    this.setState({ isShowingAllColumns, currentPage: 1 });
  }

  getAllRefs() {
    return this.props.references.filter(r => r.refersTo !== REMOVED_REFERENCE);
  }

  getErrorRefs() {
    return this.getAllRefs().filter(r => !r.refersTo);
  }

  getColumnReferences() {
    if (this.state.isShowingAllColumns) {
      return this.getAllRefs();
    } else {
      // If this ref doesn't refer to anything,
      // it's a mismatched column - and the user only wants
      // to see those
      return this.getErrorRefs();
    }
  }

  setMapping(reference, newReference) {
    if (newReference === EMPTY_REFERENCE) {
      this.props.showConfirmation({
        title: t('import_as_empty_title'),
        body: (
          <div className="import-as-empty-modal">
            <p>{t('import_as_empty_description')}</p>
            <div className="how-to-undo">
              <img
                src="/images/datasetManagementUI/how-to-undo.png"
                alt={t('how_to_undo_alt')} />
            </div>
            <p>{t('import_as_empty_r_u_sure')}</p>
          </div>
        ),
        onConfirm: () => {
          this.props.updateMapping(reference.fieldName, EMPTY_REFERENCE);
          this.setState({ mapViaOrdering: false });
        },
        confirmText: t('import_as_empty_confirm'),
        classes: ['import-as-empty-modal']
      });
    } else {
      this.props.updateMapping(reference.fieldName, newReference);
      this.setState({ mapViaOrdering: false });
    }
  }

  attachFlyouts() {
    if (this.flyoutParentEl) {
      attachTo(this.flyoutParentEl);
    }
  }

  unDropColumn(reference) {
    this.props.updateMapping(reference.fieldName, null);
  }

  dropColumn(reference) {
    const outputColumnDisplayNames = this.getOutputColumnsReferencing(reference.fieldName)
      .map((oc) => oc.display_name)
      .join(' ');

    this.props.showConfirmation({
      title: t('drop_column_title'),
      body: (
        <div className="drop-column-modal">
          <p>{formatString(t('drop_column_description'), { outputColumnDisplayNames })}</p>
        </div>
      ),
      onConfirm: () => {
        this.props.updateMapping(reference.fieldName, REMOVED_REFERENCE);
        this.setState({ mapViaOrdering: false });
      },
      confirmText: t('drop_column_confirm'),
      classes: ['import-as-empty-modal'],
      confirmBtnClasses: ['btn-error']
    });
  }

  isRemoved(inputColumnFieldName) {
    return this.props.mappings[inputColumnFieldName] === REMOVED_REFERENCE;
  }

  toggleParseOptionsExplanation() {
    this.setState({ showParseOptions: !this.state.showParseOptions });
  }

  toggleMapViaOrdering() {
    if (this.isMappingViaOrdering()) {
      this.props.clearMappings();
      this.setState({ mapViaOrdering: false });
    } else {
      if (this.state.previousInputColumns) {
        _.zip(this.state.previousInputColumns, this.props.inputColumns).forEach(([oldIc, newIc]) => {
          if (oldIc && newIc) {
            this.props.updateMapping(oldIc.field_name, newIc.field_name);
          }
        });
        this.setState({ mapViaOrdering: true });
      }
    }
  }

  isMappingViaOrdering() {
    return this.state.mapViaOrdering && !_.isEmpty(this.props.mappings);
  }

  setPage = (newPage) => {
    this.setState({ currentPage: newPage });
  }

  render() {
    const { inputColumns, gotoParseOptions } = this.props;
    const references = this.getColumnReferences();


    const referenceable = inputColumns.map(({ field_name: fieldName }) => ({
      title: fieldName, value: fieldName, group: t('data_source_columns')
    })).concat([{ title: t('empty'), value: EMPTY_REFERENCE, group: t('constants'), render: SpookyOption }]);

    const page = this.state.currentPage;
    const start = (page - 1) * PAGE_SIZE;
    const end = ((page - 1) * PAGE_SIZE) + PAGE_SIZE;
    const pagedReferences = references.slice(start, end);

    const orderingLabel = (
      this.isMappingViaOrdering() ?
      t('undo_mapping') :
      t('map_via_ordering')
    );

    let explanation;
    if (this.props.isComplete) {
      explanation = (
        <div className="title alert success">
          {t('mapping_complete')}
        </div>
      );
    } else {
      explanation = (
        <div className="title alert warning">
          {t('what_is_happening')}
          <a onClick={this.toggleParseOptionsExplanation}>
            {t('too_many_errors')}
          </a>
          {' '}
          {this.state.showParseOptions ? (
            <p>
              <br />
              {t('too_many')}
              {' '}
              <a onClick={gotoParseOptions}>{t('file_config')}</a>
            </p>
          ) : null}
        </div>
      );
    }

    const isReplace = this.props.modes.edit && !this.props.modes.update;

    return (
      <div className="schema-mismatch">
        <h3>{t('title')}</h3>
        {explanation}
        <ul className="nav-tabs">
          <li className={`tab-link ${this.state.isShowingAllColumns ? 'current' : ''}`}>
            <a onClick={() => this.setShowAllColumns(true)}>
              {t('all_columns')}
              <span className="badge badge-default">{this.getAllRefs().length}</span>
            </a>
          </li>
          <li className={`tab-link ${this.state.isShowingAllColumns ? '' : 'current'}`}>
            <a onClick={() => this.setShowAllColumns(false)}>
              {t('only_mismatched')}
              <span className="badge badge-default">{this.getErrorRefs().length}</span>
            </a>
          </li>

          <li
            className="map-using-ordering"
            ref={el => this.flyoutParentEl = el}>
            <a onClick={() => this.toggleMapViaOrdering()}>
              {orderingLabel}
            </a>

            <div className="explanation-flyout" data-flyout={FLYOUT_ID}>
              <SocrataIcon name="question" />
              <OrderingFlyout />
            </div>
          </li>
        </ul>

        <table>
          <thead>
            <tr>
              <th>{t('old_import_name')}</th>
              <th className="is-now">{t('is_now')}</th>
              <th>{t('new_import_name')}</th>
              <th>{t('advanced_options')}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="mismatch-descriptions">
              <td>{t('missing_from_input')}</td>
              <td></td>
              <td className="new-import-column">{t('change_all_columns')}</td>
              <td></td>
            </tr>
            {
              pagedReferences.map((reference) => (
                <ColumnReference
                  key={reference.fieldName}
                  isRemoved={this.isRemoved(reference.fieldName)}
                  referenceable={referenceable}
                  reference={reference}
                  newReference={this.props.mappings[reference.fieldName]}
                  effectedColumns={this.getOutputColumnsReferencing(reference.fieldName)}
                  gotoTransformEditor={this.props.gotoTransformEditor}
                  gotoGeocodeColumnEditor={this.props.gotoGeocodeColumnEditor}
                  canDropColumn={isReplace}
                  dropColumn={() => this.dropColumn(reference)}
                  unDropColumn={() => this.unDropColumn(reference)}
                  setMapping={(newReference) => this.setMapping(reference, newReference)} />
              ))
            }
          </tbody>
        </table>
        <Pager
          resultsPerPage={PAGE_SIZE}
          currentPage={this.state.currentPage}
          resultCount={references.length}
          changePage={this.setPage} />
      </div>
    );
  }
}

SchemaMismatch.propTypes = {
  params: PropTypes.object.isRequired,
  references: PropTypes.array.isRequired,
  inputColumns: PropTypes.array.isRequired,
  outputColumns: PropTypes.array.isRequired,
  gotoTransformEditor: PropTypes.func.isRequired,
  gotoSources: PropTypes.func.isRequired,
  gotoParseOptions: PropTypes.func.isRequired,
  gotoGeocodeColumnEditor: PropTypes.func.isRequired,
  mappings: PropTypes.object.isRequired,
  updateMapping: PropTypes.func.isRequired,
  showConfirmation: PropTypes.func.isRequired,
  isComplete: PropTypes.bool.isRequired,
  getGuidance: PropTypes.func.isRequired,
  clearMappings: PropTypes.func.isRequired,
  modes: Modes.shape
};


export default SchemaMismatch;
