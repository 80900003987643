import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FlashMessage from 'datasetManagementUI/containers/FlashMessageContainer';
import { FeatureFlags } from 'common/feature_flags';

class WithFlash extends Component {
  constructor() {
    super();

    this.state = {
      flashHeight: 0
    };

    this.getHeight = this.getHeight.bind(this);
  }

  getHeight(node) {
    if (!node) {
      return;
    }

    if (node.offsetHeight !== this.state.flashHeight) {
      this.setState({
        flashHeight: node.offsetHeight
      });
    }
  }

  render() {
    const { flashVisible, useFlexStyles, classNameOverride } = this.props;

    let formPadding = {
      paddingTop: flashVisible ? this.state.flashHeight + 12 : 0
    };

    const classes = `dsmp-container ${useFlexStyles ? 'dsmp-flex-container' : 'dsmp-nonflex-container'}`;

    const getClassOverride = () => {
      if (FeatureFlags.value('forgify_dsmp_metadata_modal') && classNameOverride) {
        return classNameOverride;
      } else {
        return classes;
      }
    };

    return (
      <div className={getClassOverride()} >
        <div className="dsmp-flash-container" ref={flash => this.getHeight(flash)}>
          <FlashMessage />
        </div>
        <div style={formPadding}>{this.props.children}</div>
      </div>
    );
  }
}

WithFlash.propTypes = {
  useFlexStyles: PropTypes.bool,
  flashVisible: PropTypes.bool,
  classNameOverride: PropTypes.string
  // OK...no.
  // if you say children: proptypes.array
  // if you do <WithFlash><div></div></WithFlash> it logs an error,
  // but if there are two divs, it's fine. This is stupid and proptypes
  // are stupid.
  // children: PropTypes.array
};

export default WithFlash;
