import React, { KeyboardEvent, useEffect, useRef } from 'react';
import I18n from 'common/i18n';
import { ForgeTextField } from '@tylertech/forge-react';
import PrivateFieldIcon from 'common/FormatColumn/PrivateFieldIcon/PrivateFieldIcon';

const t = (k: string, scope = 'dataset_management_ui.metadata_manage.dataset_tab') => I18n.t(k, { scope });

interface FieldLike {
  name: string;
  value: string | undefined;
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
}

interface Props {
  id?: string;
  field: FieldLike;
  inErrorState: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: () => void;
  handleFocus?: () => void;
  handleKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  focusOnMount?: boolean;
  isRequired?: boolean;
  isRestrictedForUser?: boolean;
  isPrivate?: boolean;
  useForge?: boolean;
}
const TextInput = ({ handleChange, handleFocus, handleBlur, handleKeyDown, field, focusOnMount, id, inErrorState, isRequired, isPrivate, isRestrictedForUser, useForge }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  let classes = ['dsmui-text-input'];

  if (!useForge) {
    classes.push('text-input');
  }

  useEffect(() => {
    if (focusOnMount && ref.current) {
      ref.current!.focus(); // tsc is very mean and does not believe it's not actually null even though there's a check.
    }
  }, []);

  if (inErrorState) {
    classes = [...classes, 'text-input-error', 'dsmui-text-input-error'];
  }
  if (useForge) {
    return (
      <ForgeTextField invalid={inErrorState} floatLabelType="always" required={isRequired}>
        { isPrivate && <PrivateFieldIcon/>}
        <input
          type="text"
          ref={ref}
          id={id ?? field.name}
          name={field.name}
          value={field.value}
          placeholder={field.placeholder}
          aria-label={field.label || field.name}
          aria-required={field.isRequired}
          className={classes.join(' ')}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          disabled={field.disabled}
        />
        <label htmlFor={id ?? field.name} slot="label">{ field.label || field.name }</label>
        {isRestrictedForUser && <span slot="helper-text">{t('subtitles.restricted_field')}</span>}
      </ForgeTextField>
    );
  } else {
    return (
      <input
        type="text"
        ref={ref}
        id={id ?? field.name}
        name={field.name}
        value={field.value}
        placeholder={field.placeholder}
        aria-label={field.label || field.name}
        aria-required={field.isRequired}
        className={classes.join(' ')}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        disabled={field.disabled}
      />
    );
  }
};

export default TextInput;
