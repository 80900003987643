import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import getRevisionSeq from 'common/js_utils/getRevisionSeq';
import AssetActionBar from 'common/components/AssetActionBar';
import ViewPropType from 'common/propTypes/Permissions/ViewPropType';
import { PublishButton } from 'common/components/AssetActionBar/components/publication_action/publish_button';
import { hasBeenPublished } from 'common/views/publicationHelpers';
import { PUBLICATION_STAGE } from 'common/views/constants';
import { requireApprovalRequestWithdrawal } from 'common/components/AssetActionBar/components/publication_action/utils';
import { assetIdFor, fetchApprovalsGuidanceV2, withGuidanceV2 } from 'common/core/approvals/index_new';
import { Status } from 'common/core/approvals_enums';
import SubmitForApprovalButton from 'common/components/AssetActionBar/components/submit_for_approval_button';
import EditButton from 'common/components/AssetActionBar/components/edit_button/edit_button';

import DSMUIPublishButton, { readyToPublish } from 'datasetManagementUI/containers/PublishButtonContainer';
import NotificationList from 'datasetManagementUI/containers/NotificationListContainer';
import Modal from 'datasetManagementUI/containers/ModalContainer';
import { getRowCount } from 'datasetManagementUI/reduxStuff/actions/views.js';
import { applyRevision } from 'datasetManagementUI/reduxStuff/actions/applyRevision';
import { addNotification } from 'datasetManagementUI/reduxStuff/actions/notifications';
import { updateRevision } from 'datasetManagementUI/reduxStuff/actions/revisions';
import { getSchedule } from 'datasetManagementUI/reduxStuff/actions/schedules';
import { hasPublishingAbilities } from 'datasetManagementUI/lib/util';
import { currentRevision, shapeRevisionIntoView, hasDatasetErrors } from 'datasetManagementUI/selectors';
import FlashMessage from 'datasetManagementUI/containers/FlashMessageContainer';
import { showModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import * as ModeGrantActions from 'datasetManagementUI/reduxStuff/actions/modeGrant';
import I18n from 'common/i18n';
import { hasColumnErrors } from '../../containers/ManageColumnMetadataContainer';
import { Toastmaster } from 'common/components/ToastNotification/Toastmaster';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui' });

// Export for testing
// See https://redux.js.org/recipes/writingtests#connected-components
export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      guidance: {
        public: { currentState: Status.UNSUBMITTABLE },
        internal: { currentState: Status.UNSUBMITTABLE }
      }
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    // eslint-disable-line camelcase
    const { dispatch, params } = this.props;
    dispatch(getRowCount(params.fourfour));
  }

  componentDidUpdate() {
    const { revision } = this.props;
    if (revision && !this.state.loaded) {
      this.setState({
        loaded: true
      });
    }
  }

  componentDidMount() {
    const { dispatch, params } = this.props;
    fetchApprovalsGuidanceV2(assetIdFor(this.props.view.id, getRevisionSeq(), false)).then((guidance) => {
      this.setState({ guidance });
    });

    dispatch(getSchedule(params)).catch(() => null);
  }

  render() {
    if (this.state.loaded) {
      const {
        children,
        dispatch,
        params,
        publishedViewUid,
        revision,
        entities,
        isViewer,
        metadataSatisfied,
        ui
      } = this.props;

      let { view } = this.props;
      const { guidance } = this.state;

      const publishDataset = async (permissionsObj) => {
        try {
          const proceed = await requireApprovalRequestWithdrawal(guidance);
          if (!proceed) {
            return;
          }

          // updates don't edit permissions
          if (permissionsObj) {
            // updates revision in backend
            await dispatch(updateRevision({ permissions: permissionsObj }, params));
          }

          await dispatch(applyRevision(params));
        } catch (e) {
          dispatch(addNotification('error', t('notifications.dataset_revision_error')));
          return;
        }
      };

      view = shapeRevisionIntoView(revision, view);

      const topBar = () => {
        const approvalsHelper = withGuidanceV2(guidance);
        // EN66455 FOLLOW UP: Verify this is actually how we want to determine draft and requires approval
        const isDraftAndRequiresApproval = approvalsHelper.canSubmitUpdatePublishedAssetRequest();

        const publishButtonOnClick = () => {
          const planModalProps = isDraftAndRequiresApproval
            ? { onConfirmButton: <SubmitForApprovalButton approvalsGuidance={guidance} /> }
            : { onContinue: () => publishDataset(false) };

          dispatch(showModal('Plan', planModalProps));
        };

        const publishButton = (primaryButtonProps) => {
          if (hasBeenPublished(view) && hasPublishingAbilities(view)) {
            const disabledReason = metadataSatisfied
              ? ''
              : t('home_pane.publish_dataset_button_aab.metadata_satisfied');
            const newProps = {
              ...primaryButtonProps,
              disabled: this.state.loaded && !readyToPublish(entities, ui, params),
              onClick: publishButtonOnClick,
              disabledReason: disabledReason
            };
            return <PublishButton {...newProps} />;
          } else {
            // provides custom UI (flyouts, etc.)
            return <DSMUIPublishButton {...primaryButtonProps} />;
          }
        };

        const renderPrimaryButton = (primaryButtonProps) => {
          const isPending = approvalsHelper.isPending();

          return isPending ? (
            <EditButton currentView={this.props.view} approvalsGuidance={guidance} />
          ) : (
            publishButton(primaryButtonProps)
          );
        };

        return (
          <AssetActionBar
            renderPrimaryButton={renderPrimaryButton}
            // will be skipped when using PublishButton, but not when using the DSMUI button
            onPublish={publishDataset}
            publishedViewUid={publishedViewUid}
            view={view}
            showDSLPButton={isViewer}
            user={_.get(window, 'socrata.currentUser')}
          />
        );
      };

      return (
        <div className="dataset-management-ui">
          <Toastmaster />
          {topBar()}
          <FlashMessage />
          {children}
          <NotificationList revision={this.props.revision} />
          <Modal />
        </div>
      );
    } else {
      return (
        <div id="initial-spinner-container">
          <span className="spinner-default spinner-large" />
        </div>
      );
    }
  }
}

function mapStateToProps({ entities, ui }, ownProps) {
  const { fourfour, revisionSeq } = ownProps.params;
  const view = entities.views[fourfour];
  const { publicationStage } = view || {};
  const publishedViewUid = publicationStage === PUBLICATION_STAGE.PUBLISHED && view.id;
  const revision = currentRevision(entities, revisionSeq);
  const isViewer = ModeGrantActions.isViewer(_.get(ui, 'modeGrant.grant', ''));
  const metadataSatisfied = !hasDatasetErrors(ui) && !hasColumnErrors(ui);
  return _.pickBy({
    view,
    revision,
    entities,
    ui,
    isViewer,
    metadataSatisfied,
    publishedViewUid
  });
}

Home.propTypes = {
  view: ViewPropType,
  children: PropTypes.element.isRequired,
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  publishedViewUid: PropTypes.string,
  revision: PropTypes.object,
  entities: PropTypes.object,
  ui: PropTypes.object,
  isViewer: PropTypes.bool,
  metadataSatisfied: PropTypes.bool
};

export default connect(mapStateToProps)(Home);
