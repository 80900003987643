import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import uuid from 'uuid';
import DatasetFieldset from 'datasetManagementUI/components/DatasetFieldset/DatasetFieldset';
import SourceMessage from 'datasetManagementUI/components/SourceMessage/SourceMessage';
import { browserHistory } from 'react-router';
import * as Links from 'datasetManagementUI/links/links';
import formatString from 'common/js_utils/formatString';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_sources' });

// This form strives to let the UI derive from the data, so in order to control
// the UI, it changes the data--namely the "hrefs" array. When the component loads,
// it initializes "hrefs" as an empty array. Then right before the component mounts,
// it creates an empty href and puts it in the array. This way, if we have no saved
// data, we can still show the user an empty form.

// The form then checks dsmapi to see if there is any saved href data on the
// revision. If there is, it overwrites the empty href we put into the state
// earlier. If not, then it does nothing.
class HrefForm extends Component {
  constructor() {
    super();

    this.state = {
      hrefs: [],
      currentId: 1,
      errors: []
    };

    _.bindAll(this, [
      'handleAddDataset',
      'handleAddURL',
      'handleChangeUrl',
      'handleChangeHref',
      'handleRemoveOnlyDataset',
      'handleRemoveOtherDataset',
      'handleRemoveFirstURL',
      'handleRemoveOtherURL',
      'handleSubmit'
    ]);
  }

  UNSAFE_componentWillMount() { // eslint-disable-line camelcase
    // TODO: possible race condition?
    const hrefsFromServer = !!this.props.hrefs.length;

    if (hrefsFromServer) {
      this.setState({
        hrefs: this.props.hrefs,
        currentId: this.props.hrefs.length + 1
      });
    } else {
      this.setState({
        hrefs: [this.initializeHref()]
      });
    }
  }

  componentDidUpdate() {
    if (this.props.isDirty) {
      this.props.disableSave(this.state.hrefs);
    }
  }

  componentWillUnmount() {
    this.props.clearAllFlash();
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({
      errors: []
    });

    this.props
      .validateAndSaveHrefs(this.state.hrefs)
      .then(() => {
        this.props.clearAllFlash();
        this.props.showFlash('success', 'href_save_success', t('save_success'));
        this.props.markFormClean();
        this.props.setFormErrors([]);
        this.setState({
          errors: []
        });
      })
      .catch(err => {
        this.props.clearAllFlash();
        const errors = [...this.state.errors, ...err.errors];
        this.props.showFlash('error', 'href_save_error', t('save_error'));
        this.props.setFormErrors(errors);
        this.setState({
          errors
        });
      })
      .then(() => {
        // using this .then in the absence of Promise.finally
        if (this.props.shouldExit && !this.state.errors.length) {
          browserHistory.push(Links.revisionBase(this.props.params));
        }
      });
  }

  initializeHref(datasetNum, id) {
    const idIsDefined = id != null;

    const datasetTitle = datasetNum
      ? formatString(t('dataset_title'),datasetNum)
      : '';

    const href = {
      id: idIsDefined ? id : this.state.currentId,
      data_dictionary: '',
      data_dictionary_type: '',
      title: datasetTitle,
      description: '',
      urls: {
        [uuid()]: {
          url: '',
          filetype: ''
        }
      }
    };

    if (!idIsDefined) {
      this.setState({
        currentId: this.state.currentId + 1
      });
    }

    return href;
  }

  handleAddDataset() {
    // handler for the button that adds a new dataset fieldset
    const datasetNum = this.state.hrefs.length + 1;

    this.props.markFormDirty();

    this.setState({
      hrefs: [...this.state.hrefs, this.initializeHref(datasetNum)]
    });
  }

  handleAddURL(id) {
    // handler for the button that creates a new url field in the form
    const href = this.state.hrefs.find(h => h.id === id);

    const newHref = {
      ...href,
      urls: {
        ...href.urls,
        [uuid()]: {
          url: '',
          filetype: ''
        }
      }
    };

    const newHrefs = [...this.state.hrefs.filter(h => h.id !== id), newHref];

    this.setState({
      hrefs: _.orderBy(newHrefs, 'id')
    });
  }

  handleRemoveOnlyDataset(id) {
    this.props.markFormDirty();

    const newHref = this.initializeHref(null, id);

    const newHrefs = [...this.state.hrefs.filter(h => h.id !== id), newHref];

    this.setState({
      hrefs: _.orderBy(newHrefs, 'id')
    });
  }

  handleRemoveOtherDataset(id) {
    this.props.markFormDirty();

    const newHrefs = this.state.hrefs.filter(h => h.id !== id);

    this.setState({
      hrefs: _.orderBy(newHrefs, 'id')
    });
  }

  handleRemoveFirstURL(datasetId, urlId) {
    this.props.markFormDirty();

    const href = this.state.hrefs.find(h => h.id === datasetId);

    const newHref = {
      ...href,
      urls: {
        ...href.urls,
        [urlId]: {
          url: '',
          filetype: ''
        }
      }
    };

    const newHrefs = [
      ...this.state.hrefs.filter(h => h.id !== datasetId),
      newHref
    ];

    this.setState({
      hrefs: _.orderBy(newHrefs, 'id')
    });
  }

  handleRemoveOtherURL(datasetId, urlId) {
    this.props.markFormDirty();

    const href = this.state.hrefs.find(h => h.id === datasetId);

    const newHref = {
      ...href,
      urls: _.omit(href.urls, urlId)
    };

    const newHrefs = [
      ...this.state.hrefs.filter(h => h.id !== datasetId),
      newHref
    ];

    this.setState({
      hrefs: _.orderBy(newHrefs, 'id')
    });
  }

  handleChangeUrl(id) {
    // Since urls are nested objects, they need to be udpated a little differently
    // that the other href attributes, hence this dedicated method
    return urlId => newValue => {
      this.props.markFormDirty();

      const href = this.state.hrefs.find(h => h.id === id);

      const newUrls = {
        ...href.urls,
        [urlId]: newValue
      };

      const newHref = {
        ...href,
        urls: newUrls
      };

      const newHrefs = [...this.state.hrefs.filter(h => h.id !== id), newHref];

      this.setState({
        hrefs: _.orderBy(newHrefs, 'id')
      });
    };
  }

  handleChangeHref(id, fieldname, newValue) {
    // generic handler for all href attributes except for url
    this.props.markFormDirty();

    const href = this.state.hrefs.find(h => h.id === id);

    const newHref = {
      ...href,
      [fieldname]: newValue
    };

    const newHrefs = [...this.state.hrefs.filter(h => h.id !== id), newHref];

    this.setState({
      hrefs: _.orderBy(newHrefs, 'id')
    });
  }

  render() {
    const { schemaExists, blobExists } = this.props;
    const { errors } = this.state;

    if (schemaExists || blobExists) {
      return <SourceMessage sourceExists />;
    }

    return (
      <section className="dsmp-form-container">
        <h2 className="dsmp-form-big-heading">{t('title')}</h2>
        <div className="dsmp-form-subtitle">{t('subtitle')}</div>
        <form onSubmit={this.handleSubmit}>
          <h3 className="dsmp-form-medium-heading">
            {t('add_ext_dataset')}
          </h3>
          {this.state.hrefs.map((href) => (
            <DatasetFieldset
              key={href.id}
              href={href}
              errors={errors}
              handleRemoveFirstURL={this.handleRemoveFirstURL}
              handleRemoveOtherURL={this.handleRemoveOtherURL}
              handleXClick={
                this.state.hrefs.length === 1
                  ? () => this.handleRemoveOnlyDataset(href.id)
                  : () => this.handleRemoveOtherDataset(href.id)
              }
              handleChangeUrl={this.handleChangeUrl(href.id)}
              handleChangeHref={this.handleChangeHref}
              handleAddURL={e => {
                e.preventDefault();
                this.handleAddURL(href.id);
              }} />
          ))}
          <input
            type="submit"
            id="submit-href-form"
            className="dsmp-form-hidden"
            value="submit" />
        </form>
        <button
          className="dsmp-form-add-dataset-button"
          onClick={this.handleAddDataset} >
          {t('add_dataset')}
        </button>
      </section>
    );
  }
}

HrefForm.propTypes = {
  hrefs: PropTypes.arrayOf(PropTypes.object),
  params: PropTypes.object.isRequired,
  shouldExit: PropTypes.bool.isRequired,
  validateAndSaveHrefs: PropTypes.func.isRequired,
  markFormDirty: PropTypes.func.isRequired,
  markFormClean: PropTypes.func.isRequired,
  setFormErrors: PropTypes.func.isRequired,
  clearAllFlash: PropTypes.func.isRequired,
  showFlash: PropTypes.func.isRequired,
  disableSave: PropTypes.func.isRequired,
  schemaExists: PropTypes.bool.isRequired,
  blobExists: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired
};

export default HrefForm;
