import _ from 'lodash';

import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { ShowBlobPreview } from 'datasetManagementUI/pages/ShowBlobPreview/ShowBlobPreview';
import * as Links from 'datasetManagementUI/links/links';
import { getBlobType } from 'datasetManagementUI/lib/util';
import { assetIsPublic } from 'common/views/helpers';

export function mapStateToProps({ entities }, ownProps) {
  const params = ownProps.params;
  const fourfour = params.fourfour;
  const view = entities.views[fourfour];
  const contentType = (view.blobMimeType).replace(/;.*/, '');
  const blobType = getBlobType(contentType);
  const blobFilename = view.blobFilename;
  const downloadLink = `/download/${view.id}/${encodeURIComponent(_.replace(contentType))}`;
  const scanResultUrl = `/api/views/${view.id}/files/${view.blobId}.json?method=judgement`;
  // Google Viewer cannot preview private blobs.
  const blobNotPublic = !assetIsPublic(view);
  const isPreviewable = blobType !== 'no_preview' && !(blobType === 'google_viewer' && blobNotPublic);

  const blobPreviewProps = {
    isPreviewable,
    previewUrl: downloadLink,
    previewType: blobType,
    blobFilename,
    blobNotPublic
  };

  const blobDownloadProps = {
    showDownloadSection: true,
    blobFilename,
    scanResultUrl,
    downloadLink
  };

  const blobFileInfoProps = {
    contentType,
    fileSize: view.blobFileSize,
    sourcesLink: Links.sources(params)
  };

  return {
    blobPreviewProps,
    blobFileInfoProps,
    blobDownloadProps,
    saveBlobButtonProps: {
      autosaved: true,
      revision: null,
      blobId: null,
      params
    },
    isSourceFailedButWillBeBlobbified: false,
    isSourceInProgress: false
  };
}

export const mapDispatchToProps = (dispatch, ownProps) => ({
  goHome: () => browserHistory.push(Links.revisionBase(ownProps.params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowBlobPreview);
