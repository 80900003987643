import PropTypes from 'prop-types';
import * as React from 'react';
import TransformStatus from '../TransformStatus/TransformStatus';
import TableBody from '../../containers/TableBodyContainer';

class SoQLResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayState: props.displayState
    };
  }

  UNSAFE_componentWillReceiveProps(props) { // eslint-disable-line camelcase
    this.setState({
      displayState: props.displayState
    });
  }

  render() {
    return (
      <div className="column-preview column-header">
        <table>
          <thead>
            <tr>
              <th>
                <span
                  className="col-name"
                  title={this.props.outputColumn.display_name}>
                  {this.props.outputColumn.display_name}
                </span>
              </th>
              <TransformStatus
                outputSchema={this.props.outputSchema}
                outputColumn={this.props.outputColumn}
                transform={this.props.transform}
                key={this.props.outputColumn.id}
                params={this.props.params}
                isIgnored={false}
                displayState={this.state.displayState}
                shortcuts={[]}
                flyouts={false}
                editMode={false}
                unloadedViewSource={this.props.unloadedViewSource}
                columnErrorLinkPath={this.props.columnErrorLinkPath}
                totalRows={this.props.inputSchema.total_rows} />
            </tr>
          </thead>
          <TableBody
            entities={this.props.entities}
            columns={[this.props.outputColumn]}
            displayState={this.state.displayState}
            inputSchemaId={this.props.inputSchema.id} />
        </table>
      </div>
    );
  }
}

SoQLResults.propTypes = {
  outputSchema: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  displayState: PropTypes.object.isRequired,
  outputColumn: PropTypes.object.isRequired,
  inputSchema: PropTypes.object.isRequired,
  transform: PropTypes.object.isRequired,
  entities: PropTypes.object.isRequired,
  columnErrorLinkPath: PropTypes.string.isRequired,
  unloadedViewSource: PropTypes.bool
};

export default SoQLResults;
