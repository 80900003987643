import _ from 'lodash';
import { connect } from 'react-redux';
import { hideModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import FormatColumn from 'datasetManagementUI/components/FormatColumn/FormatColumn';
import * as Selectors from 'datasetManagementUI/selectors';
import * as ShowActions from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';
import { updateRevision } from 'datasetManagementUI/reduxStuff/actions/revisions';
import { AppState, Dispatch, DsmapiResponse, Entities, Params } from 'datasetManagementUI/lib/types';
import { ColumnLike } from 'datasetManagementUI/lib/columnLike';
import { InputSchema, OutputColumn, OutputSchema, Transform, WithTransform } from 'common/types/dsmapiSchemas';
import { Expr } from 'common/types/soql';
import { ViewFlag } from 'common/types/view';
import { ColumnFormat } from 'common/types/viewColumn';
import { UndoRedoHistory, VQEColumn } from 'common/explore_grid/redux/store';
import { partialViewColumnToVQEColumn } from 'common/explore_grid/lib/selectors';

interface ExternalProps {
  outputSchema?: OutputSchema;
  saveText?: string;
  columnUpdated?: (column?: VQEColumn) => void;
}

interface StateProps {
  entities: Entities;
  inputSchema?: InputSchema;
  saveText?: string;
  columnUpdated?: (column?: VQEColumn) => void;
  vqeUndoRedoHistory?: UndoRedoHistory;
}
function mapStateToProps({ entities, ui }: AppState, props: ExternalProps): StateProps {
  const { vqeUndoRedoHistory } = ui;
  if (props.outputSchema) {
    const inputSchema = entities.input_schemas[props.outputSchema.input_schema_id];
    return {
      entities,
      inputSchema,
      saveText: props.saveText,
      columnUpdated: props.columnUpdated
    };
  } else {
    return {
      entities,
      saveText: props.saveText,
      columnUpdated: props.columnUpdated,
      vqeUndoRedoHistory
    };
  }
}

interface OwnProps {
  outputColumn: ColumnLike<never> | OutputColumn & WithTransform;
  outputSchema?: OutputSchema;
  params: Params;
}

function mergeProps(stateProps: StateProps, { dispatch }: { dispatch: Dispatch }, ownProps: OwnProps) {
  const { entities } = stateProps;
  const {
    outputColumn: maybeOutputColumn,
    outputSchema: maybeOutputSchema,
    params,
  } = ownProps;
  const onSaveForDefaultView = (format: ColumnFormat, newAST: Expr) => {
    // downcast because we know this type is correct at this point
    const outputColumn = maybeOutputColumn as OutputColumn & WithTransform;
    const outputSchema = maybeOutputSchema!;

    const outputColumns = Selectors.columnsForOutputSchema(entities, outputSchema.id);
    const desiredColumns = outputColumns.map(oc => {
      if (oc.id === outputColumn.id) {
        const oldAST = outputColumn.transform.parsed_expr;
        const cloned = ShowActions.cloneOutputColumn(oc);

        let newTransform: Partial<Transform> = cloned.transform;
        if (newAST !== oldAST) {
          // get rid of transform_expr field so dsmapi will use the parsed_expr ast
          // to generate the transform
          newTransform = {
            ..._.omit(newTransform, 'transform_expr'),
            parsed_expr: newAST
          };
        }

        return {
          ...cloned,
          format,
          transform: newTransform
        };
      } else {
        return ShowActions.cloneOutputColumn(oc);
      }
    });
    dispatch(ShowActions.newOutputSchema(
      outputSchema.input_schema_id,
      desiredColumns,
      false,
      outputSchema
    )).then((resp: DsmapiResponse<OutputSchema>) => {
      dispatch(ShowActions.redirectToOutputSchema(params, resp.resource.id));
      dispatch(hideModal());
    });
  };

  const onSaveForSoqlViewPartial = (onSaveSuccess?: (column?: VQEColumn) => void ) => {
    return (format: ColumnFormat, newAST?: Expr) => {
      const outputColumn = maybeOutputColumn as ColumnLike<never>;

      const revision = Selectors.currentRevision(entities, params.revisionSeq)!;
      const columns = (revision.metadata.columns || []).map(column => {
        if (outputColumn.field_name === column.fieldName) {
          return { ...column, format };
        }
        return column;
      });
      const metadata = {
        ...revision.metadata,
        columns: columns
      };

      dispatch(updateRevision({...revision, metadata }, params));
      dispatch(hideModal());

      if (onSaveSuccess) {
        const vqeColumns = partialViewColumnToVQEColumn(columns);
        const updatedColumnOption = vqeColumns.find(c => c.fieldName == outputColumn.field_name);
        onSaveSuccess(updatedColumnOption);
      }
    };
  };

  const isDefaultView = Selectors.currentView(entities).flags.indexOf(ViewFlag.Default) > -1;

  return {
    ...stateProps,
    ...ownProps,
    onDismiss: () => dispatch(hideModal()),
    onSave: isDefaultView ? onSaveForDefaultView : onSaveForSoqlViewPartial(stateProps.columnUpdated)
  };
}

// @ts-ignore-error a null mapDispatchToProps results in passing { dispatch },
// but this case is not covered in the types in Connect.
export default connect(mapStateToProps, null, mergeProps)(FormatColumn);
