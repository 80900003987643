import I18n from 'common/i18n';
import { ManageMetadataModalProps } from 'datasetManagementUI/containers/ManageMetadataModalContainer';
import React from 'react';
import ConfirmationModal from 'common/components/ConfirmationDialog/ConfirmationModal';
import './ManageMetadataModal.scss';
import { FeatureFlags } from 'common/feature_flags';
import { ModalContent, ModalFooter } from 'common/components/Modal';

const t = (k: string) => I18n.t(k, { scope: 'screens.edit_metadata' });

function ManageMetadataModal({
  cancelClose,
  yesReallyClose,
  pathToNewOutputSchema
}: ManageMetadataModalProps) {
  if (FeatureFlags.value('forgify_dsmp_metadata_modal')) {
    return (
      <ConfirmationModal
        forgeVersion
        onAgree={() => yesReallyClose(pathToNewOutputSchema)}
        agreeButtonText={t('unsaved_changes_close_button')}
        onCancel={cancelClose}
        cancelButtonText={t('continue_editing')}
        headerText={t('unsaved_changes_warning')}
      >
        {t('unsaved_changes_message')}
      </ConfirmationModal>
    );
  } else {
    return (
      <div id="confirm-close-modal">
        <ModalContent>
          <span className="dsmp-modal-msg">
            {t('cancel_warning')}
            <br />
            {t('cancel_warning_2')}
          </span>
        </ModalContent>
        <ModalFooter className="dsmp-modal-footer">
          <button className="btn btn-primary" onClick={cancelClose}>
            {t('continue_editing')}
          </button>
          <button className="btn btn-error" onClick={() => yesReallyClose(pathToNewOutputSchema)}>
            {t('discard_changes')}
          </button>
        </ModalFooter>
      </div>
    );
  }
}

export default ManageMetadataModal;
