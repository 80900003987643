import { View } from 'common/types/view';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import './styles/explore-grid-embedded.scss';
import './styles/explore-grid.scss';
import React from 'react';
import { Provider } from 'react-redux';
import { Store, Dispatch, MiddlewareAPI } from 'redux';
import { Option } from 'ts-option';
import GridDatasource from './components/GridDatasource';
import GridPager from './components/GridPager';
import GridSidebar from './components/GridSidebar';
import GridTable from './components/GridTable';
import { Action } from './redux/actions';
import create, { AppState, LocationParams, ExternalVQEData, UndoRedoHistory, ContextualEventHandlers, VQEColumn } from './redux/store';
import { QueryCompilationSucceeded } from 'common/types/compiler';
import ParametersEditorModal from './components/ParametersEditorModal';
import ExploreGridForgeToast from './components/ExploreGridForgeToast';
import GridRibbon, { ReturnToLink } from './components/GridRibbon';

const createMiddleware =
  (onQueryRun: (q: QueryCompilationSucceeded, columns: VQEColumn[]) => void) =>
  (store: MiddlewareAPI<Dispatch<Action>, AppState>) =>
  (next: (action: any) => any) =>
  (action: Action) => {
    if (action.type === 'QUERY_SUCCEEDED') {
      onQueryRun(action.compiled, action.columns);
    }
    return next(action);
  };

class EmbeddedVQE extends React.Component<Props> {
  store: Store<AppState>;
  constructor(props: Props) {
    super(props);
    this.store = create(
      () => {
        // this is just a noop. we don't care when the VQE URL changes.
      },
      this.externalDataProvider,
      this.props.contextualEventHandlers,
      [createMiddleware(props.onQueryRun)]
    );
  }

  externalDataProvider = (): ExternalVQEData => {
    return {
      view: this.props.view,
      columns: this.props.columns,
      parentView: this.props.parentView,
      fourfourToQuery: this.props.fourfour,
      editing: true,
      canReadFromAllParents: false,
      queryString: this.props.queryText,
      joinsEnabled: !!window.initialState?.joinsEnabled,
      clientContext: this.props.parameters,
      undoRedoHistory: this.props.undoRedoHistory
    };
  };

  render() {
    return (
      <Provider store={this.store}>
        <div id="explore-grid-app">
          <div className="grid-wrap">
            <div className="grid-main">
              <GridRibbon
                revisionSeq={this.props.revisionSeq}
                fourfour={this.props.revisionFourfour}
                returnToLinkType={ReturnToLink.DSMUI}
                onBackTo={this.props.onBackTo} />
              <GridTable />
              <GridPager />

              <GridDatasource embedded={true} />
            </div>
            <GridSidebar />
            <ParametersEditorModal />
            <ExploreGridForgeToast />
          </div>
        </div>
      </Provider>
    );
  }
}
interface Props {
  fourfour: string;
  onBackTo: () => void;
  params: LocationParams;
  view: View;
  columns: VQEColumn[];
  parentView: Option<View>;
  queryText: Option<string>;
  parameters: ClientContextVariable[];
  undoRedoHistory: UndoRedoHistory;
  onQueryRun: (q: QueryCompilationSucceeded, columns: VQEColumn[]) => void;
  contextualEventHandlers: ContextualEventHandlers;
  revisionSeq: number;
  revisionFourfour: string;
}

export default EmbeddedVQE;
