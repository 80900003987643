import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RevisionWrapperForManageMetadataComponent } from 'common/dsmapi/ManageMetadataComponent';
import { AppState, Dispatch, Params } from 'datasetManagementUI/lib/types';
import { getRevision } from './ManageColumnMetadataContainer.js';
import { Revision } from 'common/types/revision';
import { editRevision } from '../reduxStuff/actions/revisions.js';
import { setHasMetadataErrors } from 'datasetManagementUI/reduxStuff/actions/forms';

interface OwnProps {
  datasetLicenses: { title: string; value: string }[];
  onClose: () => void;
  isModal: boolean;
  showFederatedHrefMessage: boolean;
}

// Putting this in a standalone interface because I don't want it to be exposed via OwnProps
interface ReactRouterProps {
  router: {
    params: Params;
  };
}

const mapStateToProps = (state: AppState, ownProps: OwnProps & ReactRouterProps) => {
  const { entities } = state;
  const {
    router: {
      params: { revisionSeq }
    }
  } = ownProps;
  return {
    metadata: getRevision(entities.revisions, Number(revisionSeq))
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSave: async (updatedRevision: Revision) => {
      dispatch(editRevision(updatedRevision.id, updatedRevision));

      // Now that we've saved the revision,
      // we need to update the DSMP state to clear out any pre-existing metadata errors.
      dispatch(setHasMetadataErrors(false));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RevisionWrapperForManageMetadataComponent)
) as React.ComponentType<OwnProps>;
