/* eslint react/sort-comp: 0 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ColumnHeader from 'datasetManagementUI/containers/ColumnHeaderContainer';
import TransformStatus from 'datasetManagementUI/components/TransformStatus/TransformStatus';
import TableBody from 'datasetManagementUI/containers/TableBodyContainer';
import TableBodyPlaceholder from 'datasetManagementUI/containers/TableBodyPlaceholderContainer';
import { LeftSpacer, RightSpacer } from 'common/components/DatasetTable/cell/Spacer';
import { isUnloadedViewSource } from 'common/types/source';
import Modes from 'datasetManagementUI/lib/modes';
import * as DisplayState from 'datasetManagementUI/lib/displayState';
import { failedToCompile } from 'datasetManagementUI/lib/util';
import RowErrorsLink from 'datasetManagementUI/components/RowErrorsLink/RowErrorsLink';

export const DEFAULT_WIDTH = 250;

class Table extends Component {
  constructor() {
    super();
    this.state = {
      dropping: null,
      widths: {}
    };

    this.setDropping = this.setDropping.bind(this);
    this.resetDropping = this.resetDropping.bind(this);
    this.setWidth = this.setWidth.bind(this);
    this.getWidth = this.getWidth.bind(this);
  }

  componentDidMount() {
    this.initWidths(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) { // eslint-disable-line camelcase
    if (newProps.outputSchema.id !== this.props.outputSchema.id) {
      this.initWidths(newProps);
    }
  }

  initWidths(props) {
    this.setState({
      widths: props.outputColumns.reduce((acc, oc) => {
        const columnWidth = _.get(oc, 'format.width', DEFAULT_WIDTH);
        return { ...acc, [oc.id]: columnWidth };
      }, {})
    });
  }

  setWidth(colId, width) {
    this.setState({
      widths: {
        ...this.state.widths,
        [colId]: width
      }
    });
  }

  getWidth(colId) {
    return this.state.widths[colId] || DEFAULT_WIDTH;
  }

  setDropping(colId) {
    this.setState({
      dropping: colId
    });
  }

  hasCompilationErrors(props) {
    return _.some(props.outputColumns, oc => failedToCompile(oc.transform));
  }

  resetDropping() {
    this.setState({
      dropping: null
    });
  }

  render() {
    const {
      entities,
      params,
      inputSchema,
      outputSchema,
      outputColumns,
      displayState,
      getColumnErrorLinkPath,
      columnsInView,
      validatingRowIDForColumn
    } = this.props;

    const { minColIdx, maxColIdx } = columnsInView;

    const inRowErrorMode = displayState.type === DisplayState.ROW_ERRORS;
    const showFlyouts = true;
    const numRowErrors = inputSchema.num_row_errors;
    const canTransform =
      entities.sources[inputSchema.source_id] && !entities.sources[inputSchema.source_id].failed_at;

    const modes = Modes.modes(entities, params);
    const view = entities.views[params.fourfour];
    const source = entities.sources[params.sourceId];
    const unloadedViewSource = isUnloadedViewSource(source.source_type);

    return (
      <table className="table-condensed dsmp-table">
        <thead>
          <tr>
            <LeftSpacer
              columns={outputColumns}
              columnsInView={columnsInView}
              getWidth={this.getWidth} />
            {outputColumns.slice(minColIdx, maxColIdx).map(column => (
              <ColumnHeader
                view={view}
                key={column.id}
                isDropping={this.state.dropping === column.id}
                setDropping={() => this.setDropping(column.id)}
                width={this.getWidth(column.id)}
                setWidth={(w) => this.setWidth(column.id, w)}
                resetDropping={this.resetDropping}
                canTransform={canTransform}
                modes={modes}
                outputSchema={outputSchema}
                outputColumn={column}
                columnCount={outputColumns.length} />
            ))}
            <RightSpacer
              columns={outputColumns}
              columnsInView={columnsInView}
              getWidth={this.getWidth} />
          </tr>
          <tr className="column-statuses">
            <LeftSpacer
              columns={outputColumns}
              columnsInView={columnsInView}
              getWidth={this.getWidth} />
            {outputColumns.slice(minColIdx, maxColIdx).map(column => (
              <TransformStatus
                outputSchema={outputSchema}
                outputColumn={column}
                transform={column.transform}
                width={this.getWidth(column.id)}
                key={column.id}
                params={params}
                unloadedViewSource={unloadedViewSource}
                editMode={modes.edit}
                displayState={displayState}
                isDropping={this.state.dropping === column.id}
                isValidatingRowId={validatingRowIDForColumn ? validatingRowIDForColumn.id === column.id : false}
                totalRows={inputSchema.total_rows}
                flyouts={showFlyouts}
                columnErrorLinkPath={getColumnErrorLinkPath(params, column.transform, displayState)} />
            ))}
            <RightSpacer
              columns={outputColumns}
              columnsInView={columnsInView}
              getWidth={this.getWidth} />

          </tr>
          {numRowErrors > 0 && (
            <RowErrorsLink
              params={params}
              displayState={displayState}
              numRowErrors={numRowErrors}
              inRowErrorMode={inRowErrorMode} />
          )}
        </thead>
        {(unloadedViewSource && modes.edit)
          ? <TableBodyPlaceholder
            params={params}
            displayState={displayState} />
          : <TableBody
            entities={entities}
            columns={outputColumns}
            columnsInView={columnsInView}
            displayState={displayState}
            dropping={this.state.dropping}
            getWidth={this.getWidth}
            enableCellEditing
            sourceId={inputSchema.source_id}
            inputSchemaId={inputSchema.id} />}
      </table>
    );
  }
}

const OutputColumns = PropTypes.arrayOf(
  PropTypes.shape({
    position: PropTypes.number.isRequired,
    field_name: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    description: PropTypes.string,
    transform_id: PropTypes.number.isRequired,
    transform: PropTypes.shape({
      attempts: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      output_soql_type: PropTypes.string.isRequired,
      transform_expr: PropTypes.string.isRequired,
      transform_input_columns: PropTypes.array.isRequired
    })
  })
);

Table.propTypes = {
  entities: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  inputSchema: PropTypes.object.isRequired,
  outputSchema: PropTypes.object.isRequired,
  displayState: PropTypes.object.isRequired,
  getColumnErrorLinkPath: PropTypes.func.isRequired,
  columnsInView: PropTypes.object.isRequired,
  outputColumns: OutputColumns,
  validatingRowIDForColumn: PropTypes.object
};

export default Table;
