import _ from 'lodash';

import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { ShowBlobPreview } from 'datasetManagementUI/pages/ShowBlobPreview/ShowBlobPreview';
import * as Links from 'datasetManagementUI/links/links';
import { exportSource, scanResult as getScanResultUrl } from 'datasetManagementUI/links/dsmapiLinks';
import { getBlobType, getScanResult } from 'datasetManagementUI/lib/util';


export function mapStateToProps({ entities }, ownProps) {
  const params = ownProps.params;
  const sourceId = _.toNumber(params.sourceId);
  const source = entities.sources[sourceId];
  const revisionSeq = _.toNumber(params.revisionSeq);
  const revision = _.find(entities.revisions, {
    fourfour: params.fourfour,
    revision_seq: revisionSeq
  });
  const blobType = getBlobType(source.content_type);
  const scanResult = getScanResult(source.scanResult);
  const downloadLink = exportSource(revision.revision_seq, source.id);
  const scanResultUrl = getScanResultUrl(revision.revision_seq, source.id);
  const blobFilename = source.export_filename;
  // Google Viewer cannot preview private blobs.
  const isPreviewable = blobType !== 'no_preview' && blobType !== 'google_viewer';

  const blobPreviewProps = {
    isPreviewable,
    previewUrl: downloadLink,
    previewType: blobType,
    blobFilename
  };

  const blobDownloadProps = {
    showDownloadSection: true,
    blobFilename,
    scanResult,
    scanResultUrl,
    downloadLink
  };

  const blobFileInfoProps = {
    contentType: source.content_type,
    fileSize: source.filesize,
    sourcesLink: Links.sources(params)
  };

  const saveBlobButtonProps = {
    autosaved: revision.blob_id === source.id,
    blobId: source.id,
    params
  };

  const isSourceInProgress = !source.finished_at;
  // For sources where we don't care if it parses or not (aka: the fall
  // back to a blob path) the source can briefly be in a failed state
  // before we explicitly set parse_source=false on it.
  // So for the period where we exist in this state, show a loading spinner.
  const isSourceFailedButWillBeBlobbified = (
    source.failed_at !== null &&
    !!source.parse_options &&
    source.parse_options.parse_source === null
  );


  return {
    blobPreviewProps,
    blobFileInfoProps,
    blobDownloadProps,
    saveBlobButtonProps,
    isSourceFailedButWillBeBlobbified,
    isSourceInProgress
  };
}

export const mapDispatchToProps = (dispatch, ownProps) => ({
  goHome: () => browserHistory.push(Links.revisionBase(ownProps.params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowBlobPreview);
