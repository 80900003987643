import _ from 'lodash';
import uuid from 'uuid';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import DragDropUpload from 'common/components/DragDropUpload';

import SourceMessage from 'datasetManagementUI/components/SourceMessage/SourceMessage';
import * as SourceActions from 'datasetManagementUI/reduxStuff/actions/createSource';
import * as FlashActions from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import { acceptedFileTypes } from 'datasetManagementUI/lib/fileExtensions';
import * as Selectors from 'datasetManagementUI/selectors';
import { getHumanSourceName } from 'common/components/ScheduleModal/helpers';
import { FeatureFlags } from 'common/feature_flags';

const scope = 'dataset_management_ui.show_uploads';
const t = (k) => I18n.t(k, { scope })


export class DsmuiDragDropUpload extends Component {

  static preventDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  state = {
    uploadApiCallId: null
  }

  componentDidMount() {
    const { showFlashMessage, schedule } = this.props;

    if (schedule && !schedule.paused) {

      showFlashMessage({
        kind: 'warning',
        id: 'automation_warning',
        message: I18n.t(
          'dataset_management_ui.show_uploads.automation_warning', {
            source: getHumanSourceName(schedule.action)
          }
        )
      });
    }
  }

  componentWillUnmount() {
    const { hideAllFlashMessages } = this.props;

    hideAllFlashMessages();
  }


  cancelInProgressSources() {
    const { cancelSource, sourceInProgress } = this.props;

    if (sourceInProgress) {
      cancelSource(sourceInProgress);
    }
  }

  createUploadSource = (file) => {
    this.cancelInProgressSources();

    const { createUploadSource, showFlashMessage, params, parseSetting } = this.props;
    const parseableFileType = canBeParsed(file);
    const parseFileEnforced = !!parseSetting;

    if (!parseFileEnforced || parseableFileType) {
      const callId = uuid();
      this.setState({
        uploadApiCallId: callId
      });

      // This is the action creator from the _prop_ not the same function that we're currently in!
      createUploadSource(file, parseableFileType, params, callId);
    } else {
      showFlashMessage({
        kind: 'error',
        id: 'unpareseable_file_error',
        message: t('unparseable_file'),
        helpMessage: t('flash_help_message'),
        helpUrl: 'https://support.socrata.com/hc/en-us/articles/202949918'
      });
    }
  }

  createBlobSource = (file) => {
    this.cancelInProgressSources();

    const { createUploadSource, params } = this.props;
    const callId = uuid();
    this.setState({
      uploadApiCallId: callId
    });
    createUploadSource(file, false, params, callId)
  }

  selectBlobSource = (e) => {
    e.preventDefault();
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = e => {
      this.createBlobSource(e.target.files[0]);
    }
    input.click();
    return false;
  }

  handleDrop = (item) => {
    if (this.isApiCallPending()) {
      return;
    }
    const { showFlashMessage, hideFlashMessage } = this.props;

    hideFlashMessage('directory_error');
    hideFlashMessage('handle_drop_error');

    if (item.entry && item.entry.isDirectory) {
      showFlashMessage({
        kind: 'error',
        id: 'directory_error',
        message: t('directory_error_message')
      });
    } else if (item.file) {
      this.createUploadSource(item.file);
    } else {
      showFlashMessage({
        kind: 'error',
        id: 'handle_drop_error',
        message: t('flash_error_message')
      });
    }
  }

  isApiCallPending = () => {
    const callId = this.state.uploadApiCallId;
    if (!callId) {
      return false;
    }

    const apiCall = this.props.apiCalls[callId];
    if (!apiCall || apiCall.succeededAt || apiCall.failedAt) {
      return false;
    }

    return true;
  }

  render() {
    const { hrefExists } = this.props;

    if (hrefExists) {
      return <SourceMessage hrefExists={hrefExists} />;
    }

    // TODO This flashes a message on the page very briefly and it looks a little weird.
    if (this.isApiCallPending()) {
      return (
        <div>
          <h2>{t('preparing_upload')}</h2>
          <div>{t('file_dropping_disabled')}</div>
        </div>
      );
    }

    const acceptedFileTypesDescription = (
      <div>
        <div>
          <span>{t('filetypes')}</span>
          <span>{_.map(acceptedFileTypes, 'description').join(', ')}</span>
        </div>
        <div>
          {FeatureFlags.valueOrDefault('enable_explicit_blob_upload_button', false) ?
            <>
              {t('non_parsable_accepted')}
              {' '}
              <a onClick={this.selectBlobSource}>{t('upload_non_parseable')}</a>
            </> :
            t('non_parsable_accepted_legacy')
          }
        </div>
        <div>
          {t('dirs_not_supported')}
        </div>
      </div>
    );

    const messages = {
      buttonLabel: t('browse'),
      title: t('message'),
      subtitle: t('submessage')
    };

    return (
      <DragDropUpload
        onDrop={this.handleDrop}
        isBusy={this.isApiCallPending()}
        acceptedFileTypeExtensions="*"
        acceptedFileTypesDescription={acceptedFileTypesDescription}
        messages={messages} />
    );
  }

}

DsmuiDragDropUpload.propTypes = {
  cancelSource: PropTypes.func.isRequired,
  createUploadSource: PropTypes.func.isRequired,
  showFlashMessage: PropTypes.func.isRequired,
  hideFlashMessage: PropTypes.func.isRequired,
  hideAllFlashMessages: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  hrefExists: PropTypes.bool.isRequired,
  apiCalls: PropTypes.object.isRequired,
  parseSetting: PropTypes.bool,
  sourceInProgress: PropTypes.object,
  schedule: PropTypes.object
};

function canBeParsed(file) {
  const matches = _.get(file, 'name', '').match(/(\.([^\.]+))$/) || [];
  const ext = matches[2] || '';

  return _.map(acceptedFileTypes, 'extension').includes(ext.toLowerCase());
}

// export for use in tests
export const mapStateToProps = ({ entities, ui }, { params }) => {
  const { fourfour, revisionSeq } = params;
  // parseSetting == null means it will be based on the file
  const parseSetting = Selectors.getShouldParseFile(entities);
  const revision = Selectors.currentRevision(entities, _.toNumber(revisionSeq));

  return {
    hrefExists: !!_.get(revision, 'href.length', false),
    apiCalls: _.get(ui, 'apiCalls', []),
    parseSetting,
    schedule: _.get(entities, `schedules[${fourfour}]`, null)
  };
};

const mapDispatchToProps = {
  cancelSource: SourceActions.cancelSource,
  createUploadSource: SourceActions.createUploadSource,
  showFlashMessage: FlashActions.showFlashMessage,
  hideFlashMessage: FlashActions.hideFlashMessage,
  hideAllFlashMessages: FlashActions.hideAllFlashMessages
};

export default connect(mapStateToProps, mapDispatchToProps)(DsmuiDragDropUpload);
