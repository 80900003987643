import PropTypes from 'prop-types';
import React from 'react';
import StyleGuideModal from 'common/components/Modal';
import _ from 'lodash';
import ErrorsHelp from 'datasetManagementUI/containers/ErrorsHelpContainer';
import ExtraColumnsModal from 'datasetManagementUI/containers/ExtraColumnsModalContainer';
import Publishing from 'datasetManagementUI/containers/PublishingContainer';
import PublishingPlan from 'datasetManagementUI/containers/PublishingPlanContainer';
import PublishConfirmation from 'datasetManagementUI/containers/PublishConfirmationContainer';
import RowIdentifierError from 'datasetManagementUI/containers/RowIdentifierErrorContainer';
import SetupAutomation from 'datasetManagementUI/containers/SetupAutomationContainer';
import FormatColumn from 'datasetManagementUI/containers/FormatColumnContainer';
import ConfirmCloseMetadata from 'datasetManagementUI/containers/ManageMetadataModalContainer';
import SchemaMismatch from 'datasetManagementUI/containers/SchemaMismatchContainer';
import ErrorModal from 'datasetManagementUI/containers/ErrorModalContainer';
import ConfirmationModal from 'datasetManagementUI/components/ConfirmationModal/ConfirmationModal';
import SetSourceParameters from 'datasetManagementUI/components/AgentList/SetSourceParameters';
import ProvisionPluginModal from 'common/components/ProvisionPluginModal';
import AvailablePluginsModal from 'common/components/AvailablePluginsModal';
import RevisionWrapperForManageMetadataComponent from 'datasetManagementUI/containers/ManageMetadataComponentContainer.ts';
import { steps, finishOptions, title } from 'common/components/ProvisionAgentModal';
import withStepper from 'common/components/hocs/withStepper';
import PendoIdWrapper, { DisplayStyle } from 'common/components/PendoIdWrapper';
import { FeatureFlags } from 'common/feature_flags';

// TODO: take modals out of [] when styleguide Modal component proptypes are corrected
const getModalProps = (props, contentComponentName, payload) => {
  switch (contentComponentName) {
    case 'ErrorsHelp':
      return {
        ...props,
        children: [<ErrorsHelp key={1} />],
        className: 'errors-help'
      };

    case 'ExtraColumnsModal':
      return {
        ...props,
        children: [<ExtraColumnsModal key={1} {...payload} />],
        clasname: 'extra-columns-modal'
      };
    case 'Plan':
      return {
        ...props,
        children: [<PublishingPlan key={1} {...payload} />],
        className: 'publishing-plan',
        onDismiss: _.noop
      };

    case 'Publishing':
      return {
        ...props,
        children: [<Publishing key={1} />],
        className: 'publish-confirmation publishing',
        onDismiss: _.noop
      };

    case 'PublishConfirmation':
      return {
        ...props,
        children: [<PublishConfirmation key={1} />],
        className: 'publish-confirmation'
      };

    case 'RowIdentifierError':
      return {
        ...props,
        children: [<RowIdentifierError key={1} result={payload} />],
        className: 'row-identifier-error'
      };

    case 'FormatColumn':
      return {
        ...props,
        children: [<FormatColumn key={1} {...payload} />],
        className: 'format-column'
      };

    case 'SchemaMismatch':
      return {
        ...props,
        children: [<SchemaMismatch key={1} {...payload} />],
        className: 'schema-mismatch'
      };

    case 'SetupAutomation':
      return {
        ...props,
        children: [<SetupAutomation key={1} {...payload} />],
        className: 'setup-automation'
      };
    case 'ConfirmCloseMetadata': // when you remove feature flag forgify_dsmp_metadata_modal you can remove this case too
      return {
        ...props,
        children: [<ConfirmCloseMetadata key={1} />]
      };
    case 'ErrorModal':
      return {
        ...props,
        children: [<ErrorModal key={1} />],
        className: 'publishing error-modal',
        onDismiss: _.noop
      };

    case 'ConfirmationModal':
      return {
        ...props,
        children: [<ConfirmationModal key={1} {...payload} />],
        className: ''
      };
    case 'SetSourceParameters':
      return {
        ...props,
        children: [<SetSourceParameters key={1} {...payload} />],
        className: ''
      };
    case 'ProvisionPluginModal':
      return {
        ...props,
        children: [<ProvisionPluginModal key={1} {...payload} />],
        className: 'provision-plugin-modal'
      };
    case 'AvailablePlugins':
      return {
        ...props,
        children: [<AvailablePluginsModal key={1} {...payload} />],
        className: ''
      };
    case 'ProvisionAgentModal': {
      const { showAvailablePlugins, hideModal } = payload;
      const finishOptionsList = finishOptions(showAvailablePlugins, hideModal);
      const ProvisionAgentModal = withStepper({
        steps,
        showSteps: true,
        onDismiss: hideModal,
        modalTitle: title,
        selectedFinish: finishOptionsList[0],
        finishOptions: finishOptionsList
      });
      return {
        ...props,
        children: [
          <PendoIdWrapper id="provision-agent-modal" displayStyle={DisplayStyle.BLOCK}>
            <ProvisionAgentModal key={1} />
          </PendoIdWrapper>
        ],
        className: 'provision-agent-modal'
      };
    }
    default:
      console.warn('Unknown modal', contentComponentName);
      return props;
  }
};

const Modal = ({ modalState, onDismiss }) => {
  const { modals } = modalState;
  // slice to make a copy of the array so reverse does not modify it
  // reverse to make sure the modals display in the correct order
  const modalComponents = modals
    .slice()
    .reverse()
    .map(({ contentComponentName, payload }) => {
      if (FeatureFlags.value('forgify_dsmp_metadata_modal')) {
        if (contentComponentName === 'ConfirmCloseMetadata') {
          return <ConfirmCloseMetadata key={contentComponentName} />;
        }
        if (contentComponentName === 'ManageAssetMetadata') {
          const datasetLicenses = window.initialState?.datasetLicenses || [];
          return (
            <RevisionWrapperForManageMetadataComponent
              datasetLicenses={datasetLicenses}
              isModal={true}
              key={contentComponentName}
              onClose={onDismiss}
            />
          );
        }
      }
      const defaultProps = {
        children: [null],
        className: '',
        fullScreen: false,
        overlay: true,
        onDismiss
      };

      const modalProps = getModalProps(defaultProps, contentComponentName, payload);

      return (
        <div className="dsmp-modal-container" key={contentComponentName}>
          <StyleGuideModal {...modalProps} />
        </div>
      );
    });

  return <>{modalComponents}</>;
};

Modal.propTypes = {
  modalState: PropTypes.shape({
    modals: PropTypes.arrayOf(
      PropTypes.shape({
        contentComponentName: PropTypes.string,
        payload: PropTypes.object
      })
    )
  }).isRequired,
  onDismiss: PropTypes.func.isRequired
};

export default Modal;
